import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconChevronRightBold: SvgContent = ({
	cropped = false,
	...props
}) => (
	<svg
		{...(cropped
			? { viewBox: '17.67 8.67 29.25 46.27', width: '29.25', height: '46.27' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		aria-hidden={true}
		role="img"
		{...props}
	>
		<path d="M46.927 31.805 23.919 8.668l-6.245 6.282 16.759 16.855-16.759 16.856 6.246 6.28z" />
	</svg>
);
