import { mq } from '@dop/shared/styleHelpers/mediaQueries';
import { Stack } from '@dop/ui-primitives/layout/Stack';
import { Heading } from '@dop/ui-composites/typography/Heading';
import { MarkupTextBlock } from '@dop/ui-composites/typography/MarkupTextBlock';

import { TextButton } from './ui/TextButton';
import {
	useAsidePanel,
	asideBreakpoint,
	AsidePanelContainer,
} from './ui/asidePanel';
import { HTMLContent } from '@/sections/markup/Markup.types';

export const QuestionAsideButton = ({
	title,
	text,
	id,
}: {
	title: string;
	text: HTMLContent;
	id: string;
}) => {
	const { buttonProps, panelProps } = useAsidePanel<HTMLButtonElement>(id);

	return (
		<>
			<TextButton
				id={`${id}-button`}
				css={`
					grid-area: aside-button;

					${mq({ to: asideBreakpoint })`
						margin-bottom: 1rem;
					`};
					${mq({ from: asideBreakpoint })`
						text-align: right;
						justify-self: flex-end;
					`};
				`}
				{...buttonProps}
			>
				{title}
			</TextButton>
			<AsidePanelContainer {...panelProps}>
				<Stack $gap={3}>
					<Heading level={2} as="h3">
						{title}
					</Heading>
					<MarkupTextBlock html={text.html} />
				</Stack>
			</AsidePanelContainer>
		</>
	);
};
