import React from 'react';
import styled from 'styled-components';

import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { mediumLarge, mq } from '@dop/shared/styleHelpers/mediaQueries';

import { rood } from 'moduleAlias/project/src/styles/colors';
import { hemelblauw } from 'moduleAlias/project/src/styles/colors';
import { Heading } from 'moduleAlias/project/src/components/display/heading';
import { Markup } from '@dop/shared/components/markup/Markup';
import { useScrollAndFocusOnMount } from '@dop/shared/hooks/useFocusOnMount';
import { ErrorBoundary } from '@/globals/errorBoundary/ErrorBoundary';
import { translate } from '@dop/shared/translate/translate';
import { ClipboardCopyButton } from '@dop/shared/components/buttons/ClipboardCopyButton';

import {
	RESULT_STAGE_FOR_SMALL_LOT,
	RESULT_STAGE_FOR_SMALL_VOLUME,
	RESULT_STAGE_FOR_MEDIUM_LOT,
	RESULT_STAGE_FOR_LARGE_LOT_AND_MEDIUM_VOLUME,
	RESULT_STAGE_FOR_LARGE_LOT_AND_LARGE_VOLUME,
	WasteGuideState,
	WasteGuidePageData,
} from './wasteGuide.types';
import { WasteResultExplanations } from './WasteResultsExplanations';
import { FrequentWasteResults } from './WasteResultsFrequentWaste';
import { IncidentalWasteResults } from './WasteResultsIncidentalWaste';
import {
	getFrequentAlwaysSeparateWasteSlugs,
	getFrequentWasteSlugs,
	getIncidentalWasteSlugs,
	getIncidentalAlwaysSeparateWasteSlugs,
} from './wasteSelectors';
import { DownloadButton } from './wasteResultsPdf/DownloadButton';
import { getSelectionParameters } from './helpers/selectionUrlParams';
import environmentConfig from 'moduleAlias/environmentConfig';
import { findResultStage } from './reducers/wasteGuideStageReducer';
import dynamic from 'next/dynamic';

const GeneratePdfButton = dynamic(
	() =>
		import('./wasteResultsPdf/GeneratePdfButton').then(
			(mod) => mod.GeneratePdfButton
		),
	{
		ssr: false,
		loading: () => <DownloadButton disabled={true} />,
	}
);

export const useIntro = ({
	intros,
	stages,
}: {
	intros: WasteGuidePageData['wasteResults']['intros'];
	stages: WasteGuideState['stages'];
}) => {
	const resultStage = findResultStage(stages);

	switch (resultStage) {
		case RESULT_STAGE_FOR_SMALL_LOT:
			return intros.forSmallLot;
		case RESULT_STAGE_FOR_SMALL_VOLUME:
			return intros.forSmallVolume;
		case RESULT_STAGE_FOR_MEDIUM_LOT:
			return intros.forMediumLot;
		case RESULT_STAGE_FOR_LARGE_LOT_AND_MEDIUM_VOLUME:
			return intros.forLargeLotAndMediumVolume;
		case RESULT_STAGE_FOR_LARGE_LOT_AND_LARGE_VOLUME:
			return intros.forLargeLotAndLargeVolume;
		default:
			return undefined;
	}
};

const getCombinedFrequentWasteSlugs = ({
	wasteGuideState,
	wasteGuidePageData,
}: {
	wasteGuideState: WasteGuideState;
	wasteGuidePageData: WasteGuidePageData;
}) => {
	const frequentWasteSlugs = getFrequentWasteSlugs({
		wasteGuideState,
		wasteGuidePageData,
	});
	const alwaysSeparateWasteSlugs = getFrequentAlwaysSeparateWasteSlugs({
		wasteGuideState,
		wasteGuidePageData,
	});

	const wasteSlugs = new Set([
		...frequentWasteSlugs,
		...alwaysSeparateWasteSlugs,
	]);

	return wasteSlugs;
};

const getCombinedIncidentalWasteSlugs = ({
	wasteGuideState,
	wasteGuidePageData,
}: {
	wasteGuideState: WasteGuideState;
	wasteGuidePageData: WasteGuidePageData;
}) => {
	const incidentalWasteSlugs = getIncidentalWasteSlugs({
		wasteGuideState,
		wasteGuidePageData,
	});
	const alwaysSeparateWasteSlugs = getIncidentalAlwaysSeparateWasteSlugs({
		wasteGuideState,
		wasteGuidePageData,
	});

	const wasteSlugs = new Set([
		...incidentalWasteSlugs,
		...alwaysSeparateWasteSlugs,
	]);

	return wasteSlugs;
};

const PdfButton = ({
	frequentWasteSlugs,
	incidentalWasteSlugs,
	wasteResults,
	wastesMap,
	wasteGuideState,
}: {
	frequentWasteSlugs: Set<string>;
	incidentalWasteSlugs: Set<string>;
	wasteResults: WasteGuidePageData['wasteResults'];
	wastesMap: WasteGuidePageData['wastes'];
	wasteGuideState: WasteGuideState;
}) => {
	// This ready-check is built so when scrolling to the results panel, the scroll does
	// not falter (because the PdfButton is inserted during the scroll)
	const [ready, setReady] = React.useState(false);
	React.useEffect(() => {
		// give the browser some time to scroll to the results-screen first
		const timer = setTimeout(() => {
			setReady(true);
		}, 1500);
		return () => clearTimeout(timer);
	}, []);

	const errorMessage = translate('wasteGuidePdfError');

	return ready ? (
		<ErrorBoundary
			fallback={
				<div>
					<DownloadButton disabled={true} />
					<div
						css={`
							// the error message should not be wider than the button
							display: flex;
						`}
					>
						<div
							css={`
								width: 0;
								flex-grow: 1;
								color: ${rood.default};
							`}
						>
							{errorMessage}
						</div>
					</div>
				</div>
			}
		>
			<GeneratePdfButton
				frequentWasteSlugs={frequentWasteSlugs}
				incidentalWasteSlugs={incidentalWasteSlugs}
				wasteGuideState={wasteGuideState}
				wasteResults={wasteResults}
				wastesMap={wastesMap}
			/>
		</ErrorBoundary>
	) : (
		<DownloadButton disabled={true} />
	);
};

const WasteResultActions = styled('div')`
	display: flex;
	gap: 1rem;
	flex-direction: column;
	align-items: flex-start;

	${mq({ from: mediumLarge })`
		flex-direction: row;
	`}
`;

const getWasteGuideURL = (wasteGuideState: WasteGuideState) => {
	const url = new URL(environmentConfig.originUrl);
	url.pathname = window.location.pathname;

	if (wasteGuideState == null) return url.href;

	// We create the params here instead of just getting it from the window.location.href, since nextJS can be slow to update it
	const paramsStr = getSelectionParameters(
		wasteGuideState,
		window.location.search
	);

	return `${url.toString()}?${paramsStr}`;
};

export const WasteResults = ({
	wasteGuidePageData,
	wasteGuideState,
}: {
	wasteGuidePageData: WasteGuidePageData;
	wasteGuideState: WasteGuideState;
}) => {
	const { title, intros } = wasteGuidePageData.wasteResults;
	const intro = useIntro({
		intros,
		stages: wasteGuideState.stages,
	});
	const { scrollRef, focusRef } = useScrollAndFocusOnMount<
		HTMLDivElement,
		HTMLInputElement
	>();
	const frequentWasteSlugs = getCombinedFrequentWasteSlugs({
		wasteGuideState,
		wasteGuidePageData,
	});
	const incidentalWasteSlugs = getCombinedIncidentalWasteSlugs({
		wasteGuideState,
		wasteGuidePageData,
	});

	return (
		<div ref={scrollRef}>
			<div
				css={`
					position: relative;
					background-color: ${hemelblauw.lighter};
					margin-top: -2rem;
					margin-bottom: 2rem;
					padding: 2.5rem 0;
					z-index: 0;
					&:before {
						/* apply a screen-wide background */
						content: '';
						position: absolute;
						top: 0;
						bottom: 0;
						left: -9999rem;
						right: 0;
						z-index: -1;
						border-left: 9999rem solid ${hemelblauw.lighter};
						box-shadow: 9999rem 0 0 ${hemelblauw.lighter};
					}
				`}
			>
				<Heading
					ref={focusRef}
					tabIndex={-1}
					level={2}
					data-ui-test="resultsHeader"
					css={`
						margin-top: 0;
					`}
				>
					{title}
				</Heading>
				{intro != null && (
					<Markup
						html={intro.html}
						css={`
							margin-bottom: 1rem;
						`}
					/>
				)}
				<WasteResultActions>
					<PdfButton
						frequentWasteSlugs={frequentWasteSlugs}
						incidentalWasteSlugs={incidentalWasteSlugs}
						wasteResults={wasteGuidePageData.wasteResults}
						wastesMap={wasteGuidePageData.wastes}
						wasteGuideState={wasteGuideState}
					/>
					<ClipboardCopyButton
						copiedText={translate('link copied')}
						getTextToCopy={() => getWasteGuideURL(wasteGuideState)}
						{...addTestSelector('uiWasteGuideShareButton')}
					>
						{translate('share my results')}
					</ClipboardCopyButton>
				</WasteResultActions>
				<FrequentWasteResults
					frequentWasteSlugs={frequentWasteSlugs}
					frequentWasteResults={
						wasteGuidePageData.wasteResults.frequentWasteResults
					}
					wastesMap={wasteGuidePageData.wastes}
				/>
				<IncidentalWasteResults
					incidentalWasteSlugs={incidentalWasteSlugs}
					incidentalWasteResults={
						wasteGuidePageData.wasteResults.incidentalWasteResults
					}
					wastesMap={wasteGuidePageData.wastes}
				/>
				<WasteResultExplanations
					explanations={wasteGuidePageData.wasteResults.explanations}
				/>
				<WasteResultActions>
					<PdfButton
						frequentWasteSlugs={frequentWasteSlugs}
						incidentalWasteSlugs={incidentalWasteSlugs}
						wasteResults={wasteGuidePageData.wasteResults}
						wastesMap={wasteGuidePageData.wastes}
						wasteGuideState={wasteGuideState}
					/>

					<ClipboardCopyButton
						copiedText={translate('link copied')}
						getTextToCopy={() => getWasteGuideURL(wasteGuideState)}
					>
						{translate('share my results')}
					</ClipboardCopyButton>
				</WasteResultActions>
			</div>
		</div>
	);
};
