import React, { ChangeEvent, FormEvent, RefObject } from 'react';
import { WasteGuideState } from './wasteGuide.types';

export const useFieldValidation = (formRef: RefObject<HTMLFormElement>) => {
	const [dirty, setDirty] = React.useState(false);
	const [fieldsWithError, setFieldsWithError] = React.useState<Array<string>>(
		[]
	);

	React.useEffect(() => {
		if (!formRef) return undefined;
		const formElement = formRef.current;
		if (!formElement) return undefined;

		const onSubmit = () => {
			setDirty(true);
		};
		formElement.addEventListener('submit', onSubmit);
		return () => {
			formElement.removeEventListener('submit', onSubmit);
		};
	}, [formRef]);

	return {
		fieldsWithError,
		showError: dirty && fieldsWithError.length > 0,
		inputProps: {
			onChange: (
				{ currentTarget }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
				triggerDirtyCheck = true
			) => {
				// only check actual valid inputs with a name attr
				if (currentTarget.name) {
					// check if field is now valid - filter it out of the array of fieldsWithError
					if (currentTarget.validity.valid) {
						setFieldsWithError((prev) =>
							prev.filter((field) => field !== currentTarget.name)
						);
					} else {
						// found error, add it and strip duplicates
						setFieldsWithError((prev) => [
							...new Set([...prev, currentTarget.name]),
						]);
					}
				}
				if (triggerDirtyCheck) {
					setDirty(true);
				}
			},
			onInvalid: ({
				currentTarget,
			}: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				// found error, add it and strip duplicates
				setFieldsWithError((prev) => [
					...new Set([...prev, currentTarget.name]),
				]);
			},
		},
	};
};

export const useFormValidity = (formState: WasteGuideState) => {
	const [validity, setValidity] = React.useState(false);
	const formRef = React.useRef<HTMLFormElement>(null);
	React.useEffect(() => {
		if (!formRef.current) return;

		setValidity(formRef.current.checkValidity());
	}, [formState]);

	return { validity, formRef };
};
