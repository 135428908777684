import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconSluitenCircleWithBg: SvgContent = ({
	cropped = false,
	...props
}) => (
	<svg
		{...(cropped
			? { viewBox: '0 0 22 22', width: '22', height: '22' }
			: { viewBox: '0 0 24 24', width: '24', height: '24' })}
		{...props}
	>
		<title>{'Icon Sluiten Circle with Background'}</title>
		<path d="M11,0 C12.4946667,0 13.9203333,0.290333333 15.277,0.871 C16.6336667,1.45166667 17.803,2.233 18.785,3.215 C19.767,4.197 20.5483333,5.36633333 21.129,6.723 C21.7096667,8.07966667 22,9.50533333 22,11 C22,12.4946667 21.7096667,13.9203333 21.129,15.277 C20.5483333,16.6336667 19.767,17.803 18.785,18.785 C17.803,19.767 16.6336667,20.5483333 15.277,21.129 C13.9203333,21.7096667 12.4946667,22 11,22 C9.50533333,22 8.07966667,21.7096667 6.723,21.129 C5.36633333,20.5483333 4.197,19.767 3.215,18.785 C2.233,17.803 1.45166667,16.6336667 0.871,15.277 C0.290333333,13.9203333 0,12.4946667 0,11 C0,9.50533333 0.290333333,8.07966667 0.871,6.723 C1.45166667,5.36633333 2.233,4.197 3.215,3.215 C4.197,2.233 5.36633333,1.45166667 6.723,0.871 C8.07966667,0.290333333 9.50533333,0 11,0 Z M13.828,7.164 C13.5573333,7.164 13.323,7.263 13.125,7.461 L13.125,7.461 L11,9.586 L8.883,7.461 C8.685,7.263 8.448,7.164 8.172,7.164 C7.896,7.164 7.66033333,7.263 7.465,7.461 C7.26966667,7.659 7.172,7.896 7.172,8.172 C7.172,8.448 7.26833333,8.68233333 7.461,8.875 L7.461,8.875 L9.586,11 L7.461,13.117 C7.26833333,13.3096667 7.172,13.5466667 7.172,13.828 C7.172,14.104 7.26966667,14.3383333 7.465,14.531 C7.66033333,14.7236667 7.896,14.82 8.172,14.82 C8.45333333,14.82 8.69033333,14.7236667 8.883,14.531 L8.883,14.531 L11,12.414 L13.125,14.531 C13.3176667,14.7236667 13.552,14.82 13.828,14.82 C14.104,14.82 14.341,14.7223333 14.539,14.527 C14.737,14.3316667 14.836,14.096 14.836,13.82 C14.836,13.5493333 14.737,13.315 14.539,13.117 L14.539,13.117 L12.414,11 L14.539,8.875 C14.737,8.677 14.836,8.44 14.836,8.164 C14.836,7.888 14.737,7.65233333 14.539,7.457 C14.341,7.26166667 14.104,7.164 13.828,7.164 Z"></path>
	</svg>
);
