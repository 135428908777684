import styled from 'styled-components';

import { translate } from '@dop/shared/translate/translate';
import { mq } from '@dop/shared/styleHelpers/mediaQueries';
import { Heading } from 'moduleAlias/project/src/components/display/heading';
import { Link } from 'moduleAlias/project/src/components/link/link';

import { QuestionAsideButton } from './QuestionAsideButton';
import { asideBreakpoint } from './ui/asidePanel';
import { WasteQuestionExample } from './WasteQuestionExample';
import { WasteQuestionExplanation } from './WasteQuestionExplanation';
import { LinkType } from '@/globals/webApiTypes/common.types';
import { ComponentPropsWithoutRef } from 'react';
import { HTMLContent } from '@/sections/markup/Markup.types';

export const StageContainer = styled.div`
	margin-top: 3rem;
	margin-bottom: 2.6rem;
`;

export const QuestionLayout = styled.div`
	display: grid;
	grid-template-columns: minmax(0, 1fr) minmax(0, auto);
	grid-template-rows: repeat(3, auto);
	grid-template-areas:
		'title aside-button'
		'explanation explanation'
		'example example';
	align-items: baseline;
	column-gap: 1rem;

	${mq({ to: asideBreakpoint })`
		grid-template-columns: minmax(0, 1fr);
		grid-template-rows: repeat(4, auto);
		grid-template-areas:
			'title'
			'explanation'
			'example'
			'aside-button';
	`};
`;

export const QuestionTitle = ({
	questionNumber,
	children,
	...props
}: {
	questionNumber: number;
} & ComponentPropsWithoutRef<'h2'>) => {
	return (
		<Heading
			level={2}
			styleLevel={3}
			css={`
				margin-top: 0;
				grid-area: title;
			`}
			{...props}
		>
			{`${translate('question')} ${questionNumber}. `}
			{children}
		</Heading>
	);
};

const FaqLink = ({ prefix, link }: { prefix: string; link: LinkType }) => {
	return (
		<p>
			{prefix}{' '}
			<Link newWindow to={link.href}>
				{link.text}
			</Link>
		</p>
	);
};

export const Question = ({
	id,
	questionNumber,
	title,
	text,
	example,
	aside,
	faqText,
	faqLink,
}: {
	id: string;
	questionNumber: number;
	title: string;
	text?: HTMLContent;
	example?: { title: string; text: HTMLContent };
	aside?: { title: string; text: HTMLContent };
	faqText?: string;
	faqLink?: LinkType;
}) => {
	return (
		<QuestionLayout>
			<QuestionTitle id={id} questionNumber={questionNumber}>
				{title}
			</QuestionTitle>
			{text != null && <WasteQuestionExplanation text={text} />}
			{example != null && (
				<WasteQuestionExample title={example.title} text={example.text} />
			)}

			{aside != null && (
				<QuestionAsideButton
					id={`${id}-aside`}
					title={aside.title}
					text={aside.text}
				/>
			)}
			{faqLink != null && faqText != null && (
				<FaqLink prefix={faqText} link={faqLink} />
			)}
		</QuestionLayout>
	);
};
