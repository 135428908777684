import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconChevronDown: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '12 20 40.6 23.61', width: '40.6', height: '23.61' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		role="img"
		aria-hidden={true}
		{...props}
	>
		<g>
			<path d="M52.6,23.4249889 L49.1555976,20 L32.3,36.7611334 L15.4444024,20 L12,23.4249889 L32.3,43.6111111 L52.6,23.4249889" />
		</g>
	</svg>
);
