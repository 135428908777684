import React, { ComponentPropsWithoutRef, ForwardedRef } from 'react';
import styled from 'styled-components';

import { accAlwaysHide } from 'moduleAlias/project/src/styles/mixins/accHide';
import { focusStyle } from 'moduleAlias/project/src/styles/mixins/focus';
import { hemelblauw } from 'moduleAlias/project/src/styles/colors';
import { IconCheckDun } from '@dop/ui-icons/svg/checkDun';

const WasteCheckboxInput = styled.input`
	${accAlwaysHide};
`;

export const WasteCheckbox = React.forwardRef(
	(
		{
			id,
			children,
			...inputProps
		}: {
			id: string;
			children: React.ReactNode;
		} & ComponentPropsWithoutRef<'input'>,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		return (
			<div
				css={`
					flex: auto 0 1;
					position: relative;
					padding-bottom: 0.15rem;
				`}
			>
				<WasteCheckboxInput ref={ref} type="checkbox" id={id} {...inputProps} />
				<label
					htmlFor={id}
					css={`
						display: flex;
						align-items: flex-start;
						line-height: 1.2;
						width: fit-content;
						padding: 0.15rem 0.5rem 0.15rem 0.15rem;

						${WasteCheckboxInput}:checked + & {
							background-color: ${hemelblauw.lighter};
							font-weight: bold;
						}
						${WasteCheckboxInput}:focus + & {
							${focusStyle};
						}

						${WasteCheckboxInput}:checked + &:before {
							color: ${hemelblauw.default};
							background-color: currentColor;
						}
					`}
				>
					<span
						css={`
							display: block;
							flex: none;
							border: 1px solid currentColor;
							height: 1em;
							width: 1em;
							margin: 0.1em 1em 0.1em 0.1em;
							background-color: white;
						`}
					>
						<IconCheckDun
							role="img"
							aria-hidden
							css={`
								width: 100%;
								height: 100%;
								color: ${hemelblauw.default};
								display: none;
								${WasteCheckboxInput}:checked + label & {
									display: block;
								}
							`}
						/>
					</span>

					{children}
				</label>
			</div>
		);
	}
);
