import React from 'react';
import styled, { css } from 'styled-components';

import { Collapse } from '@dop/shared/components/collapse/collapse';
import { IconInfo } from '@dop/ui-icons/svg/info';
import { IconSluitenCircleWithBg } from '@dop/ui-icons/svg/sluitenCircleWithBg';
import { hemelblauw } from 'moduleAlias/project/src/styles/colors';
import { cleanButton } from 'moduleAlias/project/src/styles/mixins/button';

const ButtonTextStyled = styled.span<{
	$opened: boolean;
}>`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	color: ${({ $opened }) => ($opened ? hemelblauw.grijscontrast : 'inherit')};
`;

const iconCss = css`
	margin: 0.25rem 0.5rem;
	flex: 1 0 auto;
	fill: ${hemelblauw.grijscontrast};
`;

export const CollapseContent = ({
	id,
	children,
	buttonText,
	initialOpened = false,
}: {
	id: string;
	children: React.ReactNode;
	buttonText: React.ReactNode;
	initialOpened?: boolean;
}) => {
	const [opened, setOpened] = React.useState(initialOpened);

	return (
		<>
			<button
				type="button"
				css={cleanButton}
				onClick={() => setOpened((opened) => !opened)}
			>
				<ButtonTextStyled
					$opened={opened}
					id={`${id}-heading`}
					aria-expanded={opened}
					aria-controls={`${id}-content`}
				>
					{buttonText}

					{opened ? (
						<IconSluitenCircleWithBg
							role="img"
							height={24}
							width={24}
							css={iconCss}
						/>
					) : (
						<IconInfo role="img" height={24} width={24} css={iconCss} />
					)}
				</ButtonTextStyled>
			</button>
			<Collapse
				isOpened={opened}
				id={`${id}-content`}
				aria-labelledby={`${id}-heading`}
			>
				{children}
			</Collapse>
		</>
	);
};
