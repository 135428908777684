import { IconDownload } from '@dop/ui-icons/svg/download';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import {
	FilledBoxButton,
	FilledBoxIcon,
	FilledBoxText,
} from '@dop/ui-composites/link/FilledBoxLink';
import { translate } from '@dop/shared/translate/translate';

export const DownloadButton = ({
	disabled = false,
}: {
	disabled?: boolean;
}) => {
	return (
		<FilledBoxButton
			disabled={disabled}
			{...addTestSelector('pdfDownloadButton')}
		>
			<FilledBoxIcon as={IconDownload}></FilledBoxIcon>
			<FilledBoxText>{translate('download my results')}</FilledBoxText>
		</FilledBoxButton>
	);
};
