import { Markup } from '@dop/shared/components/markup/Markup';
import { HTMLContent } from '@/sections/markup/Markup.types';

export const WasteQuestionExplanation = ({ text }: { text: HTMLContent }) => {
	return (
		<div
			css={`
				grid-area: explanation;
			`}
		>
			<Markup
				html={text.html}
				css={`
					margin-bottom: 1rem;
				`}
			/>
		</div>
	);
};
