import React from 'react';

import { IconPijlDelen } from '@dop/ui-icons/svg/pijlDelen';
import { IconCheckDik } from '@dop/ui-icons/svg/checkDik';
import {
	OutlinedBoxButton,
	OutlinedBoxIcon,
	OutlinedBoxText,
} from '@dop/ui-composites/link/OutlinedBoxLink';
import { translate } from '@dop/shared/translate/translate';

type Props = {
	children: React.ReactNode;
	getTextToCopy: () => string;
	copiedText: string;
	disabled?: boolean;
};

/**
 * A button that can be clicked to copy some text (provided via `getTextToCopy` function) to the clipboard.
 * When clicked, the button displays a temporary message indicating the text has been copied
 */
export const ClipboardCopyButton = (props: Props) => {
	const {
		children,
		getTextToCopy,
		copiedText = translate('link copied'),
		disabled,
		...restProps
	} = props;

	const [copied, setCopied] = React.useState(false);

	const copyText = async () => {
		// navigator.clipboard is only accessible on https or localhost
		if (navigator.clipboard && window.isSecureContext) {
			await navigator.clipboard.writeText(getTextToCopy());
		} else {
			// Use the 'out of viewport hidden text area' trick
			const textArea = document.createElement('textarea');
			textArea.value = getTextToCopy();

			// Move textarea out of the viewport so it's not visible
			textArea.style.position = 'absolute';
			textArea.style.left = '-999999px';

			document.body.prepend(textArea);
			textArea.select();

			try {
				document.execCommand('copy');
			} catch (error) {
				console.error(error);
			} finally {
				textArea.remove();
			}
		}
		setCopied(true);
	};

	React.useEffect(() => {
		let timer: NodeJS.Timeout;
		if (copied) {
			// show copied message for 3 seconds
			timer = setTimeout(() => {
				setCopied(false);
			}, 3000);
		}
		return () => {
			clearTimeout(timer);
		};
	}, [copied]);

	return (
		<OutlinedBoxButton
			disabled={copied || disabled}
			onClick={copyText}
			{...restProps}
		>
			<OutlinedBoxIcon as={copied ? IconCheckDik : IconPijlDelen} />
			<OutlinedBoxText>{copied ? copiedText : children}</OutlinedBoxText>
		</OutlinedBoxButton>
	);
};
