import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconInfo: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '0 0 26 26', width: '26', height: '26' }
			: { viewBox: '0 0 26 26', width: '26', height: '26' })}
		{...props}
	>
		<g fillRule="nonzero">
			<path d="M15.039 17.783c-.981 1.473-1.979 2.608-3.658 2.608-1.146-.187-1.617-1.008-1.369-1.845l2.16-7.154c.053-.175-.035-.362-.195-.419-.159-.056-.471.151-.741.447L9.93 12.991c-.035-.264-.004-.7-.004-.876.981-1.473 2.593-2.635 3.686-2.635 1.039.106 1.531.937 1.35 1.85l-2.175 7.189c-.029.162.057.327.204.379.16.056.496-.151.767-.447l1.305-1.57c.035.264-.024.726-.024.902Zm-.291-9.344c-.826 0-1.496-.602-1.496-1.488s.67-1.487 1.496-1.487 1.496.602 1.496 1.487c0 .887-.67 1.488-1.496 1.488Z" />
			<path d="M13 0C5.82 0 0 5.82 0 13s5.82 13 13 13 13-5.82 13-13S20.18 0 13 0Zm0 2c6.075 0 11 4.925 11 11s-4.925 11-11 11S2 19.075 2 13 6.925 2 13 2Z" />
		</g>
	</svg>
);
