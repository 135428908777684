import styled from 'styled-components';

import { useScrollAndFocusOnMount } from '@dop/shared/hooks/useFocusOnMount';
import { StageContainer, Question } from './WasteQuestion';
import { WasteItemInfoPopupButton } from './WasteItemInfoPopup';
import { WasteCheckbox } from './ui/WasteCheckbox';
import { FieldsetGroup } from './ui/FieldsetGroup';
import { WasteInputContainer } from './ui/WasteInputContainer';
import { WasteQuestionFaqLink } from './WasteQuestionFaqLink';
import { WasteGuidePageData, WasteGuideState } from './wasteGuide.types';
import { WasteGuideActions } from './reducers/wasteGuideReducer';

const WasteItemLabel = ({
	title,
	separateForFrequency,
}: {
	title: string;
	separateForFrequency?: string;
}) => {
	return (
		<span>
			{title}
			{Boolean(separateForFrequency) && (
				<span
					css={`
						font-style: italic;
						font-weight: normal;
					`}
				>
					{' – '}
					{separateForFrequency}
				</span>
			)}
		</span>
	);
};

const WasteQuestionFaqLinkStyled = styled(WasteQuestionFaqLink)`
	margin-top: 1rem;
`;

export const QuestionFrequentWasteForLargeCompany = ({
	questionNumber,
	question,
	answers,
	selection,
	frequentWasteForLargeCompanyChecked,
	frequentWasteForLargeCompanyUnchecked,
	wastesMap,
}: {
	questionNumber: number;
	question: WasteGuidePageData['wasteQuestionsForLargeCompany']['frequentWaste'];
	answers: WasteGuidePageData['wasteAnswers']['frequentWasteForLargeCompanyAnswers'];
	selection: WasteGuideState['frequentWasteForLargeCompany'];
	frequentWasteForLargeCompanyChecked: WasteGuideActions['frequentWasteForLargeCompanyChecked'];
	frequentWasteForLargeCompanyUnchecked: WasteGuideActions['frequentWasteForLargeCompanyUnchecked'];
	wastesMap: WasteGuidePageData['wastes'];
}) => {
	const questionId = 'waste-guide-question-frequent-waste-for-large-company';
	const { scrollRef, focusRef } = useScrollAndFocusOnMount<
		HTMLDivElement,
		HTMLInputElement
	>();
	const { title, text, example, aside, faqText, faqLink } = question;

	return (
		<StageContainer ref={scrollRef}>
			<FieldsetGroup aria-labelledby={questionId}>
				<Question
					id={questionId}
					questionNumber={questionNumber}
					title={title}
					text={text}
					example={example}
					aside={aside}
				/>
				<WasteInputContainer>
					{answers.map((slug, index) => {
						const wasteItem = wastesMap[slug];

						if (wasteItem == null) return null;

						const { title, separateForFrequency } = wasteItem;
						return (
							<div
								key={slug}
								css={`
									display: flex;
									align-items: flex-start;
								`}
							>
								<WasteCheckbox
									ref={index === 0 ? focusRef : undefined}
									name={`${questionId}-${slug}`}
									id={`${questionId}-${slug}`}
									data-ui-test={`${questionId}-${slug}`}
									checked={selection.has(slug)}
									onChange={(event) => {
										const checked = event.currentTarget.checked;

										if (checked) {
											frequentWasteForLargeCompanyChecked(slug);
										} else {
											frequentWasteForLargeCompanyUnchecked(slug);
										}
									}}
								>
									<WasteItemLabel
										title={title}
										separateForFrequency={separateForFrequency}
									/>
								</WasteCheckbox>
								<WasteItemInfoPopupButton
									slug={slug}
									questionId={questionId}
									wastesMap={wastesMap}
								/>
							</div>
						);
					})}
				</WasteInputContainer>
				{faqLink != null && faqText != null && (
					<WasteQuestionFaqLinkStyled faqText={faqText} faqLink={faqLink} />
				)}
			</FieldsetGroup>
		</StageContainer>
	);
};
