import { useRef } from 'react';

import { WasteGuideIntroduction } from './WasteGuideIntroduction';
import { useWasteGuideReducer } from './reducers/wasteGuideReducer';
import { WasteGuidePageData } from './wasteGuide.types';
import { WasteForm } from './WasteForm';
import { includesResultStage } from './reducers/wasteGuideStageReducer';
import { WasteResults } from './WasteResults';

export const WasteGuide = ({ pageData }: { pageData: WasteGuidePageData }) => {
	const firstQuestionFocusRef = useRef<HTMLInputElement>(null);
	const firstQuestionScrollRef = useRef<HTMLDivElement>(null);
	const { wasteGuideState, actions } = useWasteGuideReducer();

	return (
		<>
			<WasteGuideIntroduction
				firstQuestionFocusRef={firstQuestionFocusRef}
				firstQuestionScrollRef={firstQuestionScrollRef}
				wasteGuideStarted={actions.wasteGuideStarted}
				stages={wasteGuideState.stages}
				wasteAnswers={pageData.wasteAnswers}
				startScreen={pageData.startScreen}
				wastesMap={pageData.wastes}
			/>
			<WasteForm
				wasteGuideState={wasteGuideState}
				actions={actions}
				pageData={pageData}
				firstQuestionFocusRef={firstQuestionFocusRef}
				firstQuestionScrollRef={firstQuestionScrollRef}
			/>
			{includesResultStage(wasteGuideState.stages) && (
				<WasteResults
					wasteGuidePageData={pageData}
					wasteGuideState={wasteGuideState}
				/>
			)}
		</>
	);
};
