import React from 'react';

import {
	FilledBoxButton,
	FilledBoxIcon,
	FilledBoxText,
} from '@dop/ui-composites/link/FilledBoxLink';
import { IconChevronDown } from '@dop/ui-icons/svg/chevronDown';
import { PositiveThemedColor } from '@dop/ui-primitives/colorTheme/ColorTheme.types';

export const WasteGuideSubmit = ({
	validity,
	children,
}: {
	validity: boolean;
	children: React.ReactNode;
}) => {
	// Mute invalid button to indicate you still need to answer (we don't use the disabled property, since we want to show an error message on click)
	const boxColor: PositiveThemedColor = validity
		? ['positive', 'secondary']
		: ['positive', 'muted'];

	return (
		<FilledBoxButton
			type="submit"
			boxProps={{ $backgroundColor: boxColor, $borderColor: boxColor }}
			data-ui-test="afvalNextButton"
		>
			<FilledBoxText>{children}</FilledBoxText>
			<FilledBoxIcon as={IconChevronDown} />
		</FilledBoxButton>
	);
};
