import styled from 'styled-components';

import { useScrollAndFocusOnMount } from '@dop/shared/hooks/useFocusOnMount';

import { StageContainer, Question } from './WasteQuestion';
import { WasteItemInfoPopupButton } from './WasteItemInfoPopup';
import { WasteCheckbox } from './ui/WasteCheckbox';
import { FieldsetGroup } from './ui/FieldsetGroup';
import { WasteInputContainer } from './ui/WasteInputContainer';
import { WasteQuestionFaqLink } from './WasteQuestionFaqLink';
import {
	FREQUENT_WASTE_FOR_LARGE_COMPANY_STAGE,
	FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE,
	WasteGuidePageData,
	WasteGuideState,
} from './wasteGuide.types';
import { WasteGuideActions } from './reducers/wasteGuideReducer';
import { useShowInterimResult } from './WasteInterimResult';

const WasteItemLabel = ({
	title,
	separateForVolume,
}: {
	title: string;
	separateForVolume?: string;
}) => {
	return (
		<span>
			{title}
			{Boolean(separateForVolume) && (
				<span
					css={`
						font-style: italic;
						font-weight: normal;
					`}
				>
					{' – '}
					{separateForVolume}
				</span>
			)}
		</span>
	);
};

const WasteQuestionFaqLinkStyled = styled(WasteQuestionFaqLink)`
	margin-top: 1rem;
`;

const getIncidentaWasteAnswers = ({
	answers,
	stages,
	frequentWasteForMediumCompany,
	frequentWasteForLargeCompany,
}: {
	answers: WasteGuidePageData['wasteAnswers']['incidentalWasteAnswers'];
	stages: WasteGuideState['stages'];
	frequentWasteForMediumCompany: WasteGuideState['frequentWasteForMediumCompany'];
	frequentWasteForLargeCompany: WasteGuideState['frequentWasteForLargeCompany'];
}) => {
	if (stages.includes(FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE)) {
		return answers.filter((slug) => {
			return slug !== frequentWasteForMediumCompany;
		});
	}
	if (stages.includes(FREQUENT_WASTE_FOR_LARGE_COMPANY_STAGE)) {
		return answers.filter((slug) => {
			return !frequentWasteForLargeCompany.has(slug);
		});
	}

	return answers;
};

export const QuestionIncidentalWaste = ({
	questionNumber,
	focusOnMount,
	showInterimResult,
	question,
	answers,
	selection,
	stages,
	frequentWasteForMediumCompany,
	frequentWasteForLargeCompany,
	incidentalWasteChecked,
	incidentalWasteUnchecked,
	wastesMap,
}: {
	questionNumber: number;
	focusOnMount: boolean;
	showInterimResult: ReturnType<typeof useShowInterimResult>;
	question: WasteGuidePageData['commonWasteQuestions']['incidentalWaste'];
	answers: WasteGuidePageData['wasteAnswers']['incidentalWasteAnswers'];
	selection: WasteGuideState['incidentalWaste'];
	stages: WasteGuideState['stages'];
	frequentWasteForMediumCompany: WasteGuideState['frequentWasteForMediumCompany'];
	frequentWasteForLargeCompany: WasteGuideState['frequentWasteForLargeCompany'];
	wastesMap: WasteGuidePageData['wastes'];

	incidentalWasteChecked: WasteGuideActions['incidentalWasteChecked'];
	incidentalWasteUnchecked: WasteGuideActions['incidentalWasteUnchecked'];
}) => {
	const questionId = 'waste-guide-question-incidental-waste';
	const { scrollRef, focusRef } = useScrollAndFocusOnMount<
		HTMLDivElement,
		HTMLInputElement
	>();
	const { title, text, example, aside, faqText, faqLink } = question;

	const incidentalWasteAnswers = getIncidentaWasteAnswers({
		answers,
		stages,
		frequentWasteForMediumCompany,
		frequentWasteForLargeCompany,
	});
	return (
		// Add a scroll-ref to the next question in case there is not an interim-result for a small lotsize / low waste-volume
		<StageContainer ref={showInterimResult ? null : scrollRef}>
			<FieldsetGroup aria-labelledby={questionId}>
				<Question
					id={questionId}
					questionNumber={questionNumber}
					title={title}
					text={text}
					example={example}
					aside={aside}
				/>
				<WasteInputContainer>
					{incidentalWasteAnswers.map((slug, index) => {
						const wasteItem = wastesMap[slug];

						if (wasteItem == null) return null;

						const { title, separateForVolume } = wasteItem;
						return (
							<div
								key={slug}
								css={`
									display: flex;
									align-items: flex-start;
								`}
							>
								<WasteCheckbox
									ref={focusOnMount && index === 0 ? focusRef : undefined}
									name={`${questionId}-${slug}`}
									id={`${questionId}-${slug}`}
									data-ui-test={`${questionId}-${slug}`}
									checked={selection.has(slug)}
									onChange={(event) => {
										const checked = event.currentTarget.checked;

										if (checked) {
											incidentalWasteChecked(slug);
										} else {
											incidentalWasteUnchecked(slug);
										}
									}}
								>
									<WasteItemLabel
										title={title}
										separateForVolume={separateForVolume}
									/>
								</WasteCheckbox>
								<WasteItemInfoPopupButton
									slug={slug}
									questionId={questionId}
									wastesMap={wastesMap}
								/>
							</div>
						);
					})}
				</WasteInputContainer>
				{faqLink != null && faqText != null && (
					<WasteQuestionFaqLinkStyled faqText={faqText} faqLink={faqLink} />
				)}
			</FieldsetGroup>
		</StageContainer>
	);
};
