import { IconImportantSquare } from '@dop/ui-icons/svg/importantSquare';
import { hemelblauw } from 'moduleAlias/project/src/styles/colors';
import { Markup } from '@dop/shared/components/markup/Markup';
import { useScrollAndFocusOnMount } from '@dop/shared/hooks/useFocusOnMount';

import { StageContainer } from './WasteQuestion';
import { ANSWERS, WasteGuideState } from './wasteGuide.types';
import { WasteGuidePageData } from './wasteGuide.types';
import { HTMLContent } from '@/sections/markup/Markup.types';

export const SHOW_INTERIM_RESULT_FOR_SMALL_LOT =
	'SHOW_INTERIM_RESULT_FOR_SMALL_LOT';
export const SHOW_INTERIM_RESULT_FOR_SMALL_WASTE_VOLUME =
	'SHOW_INTERIM_RESULT_FOR_SMALL_WASTE_VOLUME';

export const useShowInterimResult = ({
	lotSize,
	wasteVolumeForMediumLot,
	wasteVolumeForLargeLot,
}: WasteGuideState) => {
	switch (lotSize) {
		case ANSWERS.LOT_SIZE.SMALL:
			return SHOW_INTERIM_RESULT_FOR_SMALL_LOT;
		case ANSWERS.LOT_SIZE.MEDIUM:
			return wasteVolumeForMediumLot === ANSWERS.WASTE_VOLUME.INCIDENTAL
				? SHOW_INTERIM_RESULT_FOR_SMALL_WASTE_VOLUME
				: false;
		case ANSWERS.LOT_SIZE.LARGE:
			return wasteVolumeForLargeLot === ANSWERS.WASTE_VOLUME.INCIDENTAL
				? SHOW_INTERIM_RESULT_FOR_SMALL_WASTE_VOLUME
				: false;
		default:
			return false;
	}
};

const WasteInterimResultComponent = ({ text }: { text: HTMLContent }) => {
	const { scrollRef, focusRef } = useScrollAndFocusOnMount<
		HTMLDivElement,
		HTMLDivElement
	>();
	return (
		<StageContainer ref={scrollRef}>
			<div
				ref={focusRef}
				tabIndex={-1}
				css={`
					display: flex;
					background-color: ${hemelblauw.lighter};
					padding-right: 1.5rem;
				`}
			>
				<div
					css={`
						flex: none;
						width: 2.5rem;
						height: 2.5rem;
						padding: 0.3rem;
					`}
				>
					<IconImportantSquare
						role="img"
						aria-label="belangrijk"
						css={`
							width: 100%;
							height: 100%;
							color: ${hemelblauw.grijscontrast};
						`}
					/>
				</div>
				<Markup
					html={text.html}
					data-ui-test="attentionText"
					css={`
						flex: auto;
						padding-top: 0.2rem;
						padding-bottom: 0.3rem;

						&:after {
							content: '';
							display: block;
							height: 0;
							margin-top: 1rem;
							margin-bottom: -1rem;
						}
					`}
				/>
			</div>
		</StageContainer>
	);
};

export const WasteInterimResult = ({
	showInterimResult,
	interimResults,
}: {
	showInterimResult:
		| typeof SHOW_INTERIM_RESULT_FOR_SMALL_LOT
		| typeof SHOW_INTERIM_RESULT_FOR_SMALL_WASTE_VOLUME
		| false;
	interimResults: WasteGuidePageData['interimResults'];
}) => {
	switch (showInterimResult) {
		case SHOW_INTERIM_RESULT_FOR_SMALL_LOT:
			return (
				<WasteInterimResultComponent text={interimResults.smallLot.text} />
			);
		case SHOW_INTERIM_RESULT_FOR_SMALL_WASTE_VOLUME:
			return (
				<WasteInterimResultComponent
					text={interimResults.smallWasteVolume.text}
				/>
			);
		default:
			return null;
	}
};
