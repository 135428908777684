import React, { ChangeEvent } from 'react';

import { slugify } from '@dop/shared/helpers/slugify';
import { mq } from '@dop/shared/styleHelpers/mediaQueries';
import { useScrollAndFocusOnMount } from '@dop/shared/hooks/useFocusOnMount';
import { translate } from '@dop/shared/translate/translate';
import { wasteGuideConfig } from 'moduleAlias/project/src/pages/wasteGuide/wasteGuideConfig';

import { Question, StageContainer } from './WasteQuestion';
import { WasteItemInfoPopupButton } from './WasteItemInfoPopup';
import { FieldsetGroup } from './ui/FieldsetGroup';
import { shouldFocusRadioInput } from './ui/WasteRadio';
import { WasteMatrixRadio, WasteMatrixRadioGroup } from './WasteMatrixRadio';
import {
	Table,
	Tbody,
	Tr,
	GroupTh,
	ColumnTh,
	WasteTh,
	WasteTd,
	wasteMatrixTableBreakpoint,
} from './WasteMatrixTable';
import { WasteQuestionFaqLink } from './WasteQuestionFaqLink';
import { WasteQuestionError } from './WasteQuestionError';
import { useFieldValidation } from './useFieldValidation';
import { WasteGuidePageData, WasteGuideState } from './wasteGuide.types';
import { WasteGuideActions } from './reducers/wasteGuideReducer';

const defaultMatrixSelection = wasteGuideConfig.frequencyOptions.never.slug;

const AlwaysSeparateWasteMatrixRadio = ({
	label,
	optionSlug,
	index,
	focusRef,
	wasteSlug,
	wasteId,
	onInvalid,
	onChange,
	selection,
	alwaysSeparateWasteChecked,
}: {
	label: string;
	optionSlug: string;
	index: number;
	focusRef?: React.RefObject<HTMLInputElement>;
	wasteSlug: string;
	wasteId: string;
	onInvalid: (event: React.FormEvent<HTMLInputElement>) => void;
	onChange: (
		event: ChangeEvent<HTMLInputElement>,
		isIncidental?: boolean
	) => void;
	selection: WasteGuideState['alwaysSeparateWaste'];
	alwaysSeparateWasteChecked: WasteGuideActions['alwaysSeparateWasteChecked'];
}) => {
	const selectedOption = selection[wasteSlug] ?? defaultMatrixSelection;

	return (
		<WasteMatrixRadio
			key={optionSlug}
			ref={
				shouldFocusRadioInput({
					selection: selectedOption,
					value: optionSlug,
					index: index,
				})
					? focusRef
					: undefined
			}
			name={wasteId}
			value={optionSlug}
			id={`${wasteId}-${optionSlug}`}
			data-ui-test={`${wasteId}-${optionSlug}`}
			required
			checked={optionSlug === selectedOption}
			onInvalid={onInvalid}
			onChange={(event) => {
				onChange(event, false);
				alwaysSeparateWasteChecked({
					slug: wasteSlug,
					value: optionSlug,
				});
			}}
			label={label}
		/>
	);
};

const WasteItemHeading = ({
	slug,
	questionId,
	wasteMap,
}: {
	slug: string;
	questionId: string;
	wasteMap: WasteGuidePageData['wastes'];
}) => {
	const wasteItem = wasteMap[slug];

	if (wasteItem == null) return null;

	return (
		<div
			css={`
				${mq({ from: wasteMatrixTableBreakpoint })`
					display: flex;
					align-items: flex-start;
				`};
				line-height: 1.2;
			`}
		>
			<span
				css={`
					padding: 0.15rem 0.5rem 0.15rem 0;
				`}
			>
				{wasteItem.title}
			</span>
			<WasteItemInfoPopupButton
				slug={slug}
				questionId={questionId}
				wastesMap={wasteMap}
				css={`
					margin-left: 0;
				`}
			/>
		</div>
	);
};

export const QuestionAlwaysSeparateWaste = ({
	questionNumber,
	formRef,
	question,
	answerGroups,
	selection,
	wasteMap,
	alwaysSeparateWasteChecked,
}: {
	questionNumber: number;
	formRef: React.RefObject<HTMLFormElement>;
	question: WasteGuidePageData['commonWasteQuestions']['alwaysSeparateWaste'];
	answerGroups: WasteGuidePageData['wasteAnswers']['alwaysSeparateWasteAnswers'];
	selection: WasteGuideState['alwaysSeparateWaste'];
	wasteMap: WasteGuidePageData['wastes'];
	alwaysSeparateWasteChecked: WasteGuideActions['alwaysSeparateWasteChecked'];
}) => {
	const { frequencyOptions } = wasteGuideConfig;

	const questionId = 'waste-guide-question-always-separate-waste';
	const { scrollRef, focusRef } = useScrollAndFocusOnMount<
		HTMLDivElement,
		HTMLInputElement
	>();
	const { title, text, example, aside, faqText, faqLink, errorMessage } =
		question;

	const {
		fieldsWithError,
		showError,
		inputProps: { onInvalid, onChange },
	} = useFieldValidation(formRef);

	return (
		<StageContainer ref={scrollRef}>
			<FieldsetGroup aria-labelledby={questionId}>
				<Question
					id={questionId}
					questionNumber={questionNumber}
					title={title}
					text={text}
					example={example}
					aside={aside}
				/>
				{faqLink != null && faqText != null && (
					<WasteQuestionFaqLink faqText={faqText} faqLink={faqLink} />
				)}

				<Table>
					<Tbody>
						{answerGroups.map(({ title, wastes }, groupIndex) => {
							const groupHeadingId = slugify(title);
							const wasteKindHeadingId = `${groupHeadingId}-kind`;
							const wasteFrequencyHeadingId = `${groupHeadingId}-frequency`;

							return (
								<React.Fragment key={groupHeadingId}>
									<Tr>
										<GroupTh id={groupHeadingId} $index={groupIndex}>
											{title}
										</GroupTh>
									</Tr>
									<Tr>
										<ColumnTh id={wasteKindHeadingId}>
											{translate('waste type')}
										</ColumnTh>
										<ColumnTh id={wasteFrequencyHeadingId}>
											{translate('frequency')}
										</ColumnTh>
									</Tr>
									{wastes.map((wasteSlug, wasteIndex) => {
										const wasteHeadingId = `${groupHeadingId}-${wasteSlug}`;

										return (
											<Tr key={wasteSlug}>
												<WasteTh
													$index={wasteIndex}
													id={wasteHeadingId}
													headers={`${groupHeadingId} ${wasteKindHeadingId}`}
												>
													<WasteItemHeading
														slug={wasteSlug}
														questionId={questionId}
														wasteMap={wasteMap}
													/>
													<WasteQuestionError
														showError={
															showError &&
															fieldsWithError.includes(wasteHeadingId)
														}
														id={`error-${wasteHeadingId}`}
													>
														{errorMessage}
													</WasteQuestionError>
												</WasteTh>
												<WasteTd
													$index={wasteIndex}
													headers={`${wasteHeadingId} ${wasteFrequencyHeadingId}`}
												>
													<WasteMatrixRadioGroup>
														{Object.values(frequencyOptions).map(
															({ title, slug: optionSlug }, optionIndex) => {
																return (
																	<AlwaysSeparateWasteMatrixRadio
																		key={optionSlug}
																		focusRef={
																			groupIndex === 0 && wasteIndex === 0
																				? focusRef
																				: undefined
																		}
																		index={optionIndex}
																		wasteSlug={wasteSlug}
																		wasteId={wasteHeadingId}
																		optionSlug={optionSlug}
																		onInvalid={onInvalid}
																		onChange={onChange}
																		selection={selection}
																		alwaysSeparateWasteChecked={
																			alwaysSeparateWasteChecked
																		}
																		label={title}
																	/>
																);
															}
														)}
													</WasteMatrixRadioGroup>
												</WasteTd>
											</Tr>
										);
									})}
								</React.Fragment>
							);
						})}
					</Tbody>
				</Table>
			</FieldsetGroup>
		</StageContainer>
	);
};
