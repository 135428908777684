import styled from 'styled-components';

import { Link } from 'moduleAlias/project/src/components/link/link';
import { noBullets } from '@dop/shared/styleHelpers/list';
import { grijs, hemelblauw } from 'moduleAlias/project/src/styles/colors';
import { MainRowLayout } from '@dop/shared/components/pageLayout/MainRowLayout';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { LinkType } from '@/globals/webApiTypes/common.types';

const RelatedBannerBackground = styled.div`
	background-color: ${grijs.lightest};
	line-height: 1.6;
	padding-top: 0.5rem;
	padding-bottom: 0.6rem;
`;
const RelatedBannerText = styled.span`
	margin-right: 0.8rem;
	display: inline-block;
`;
const RelatedBannerList = styled.ul`
	${noBullets};
	display: inline;
`;
const RelatedBannerListItem = styled.li`
	display: inline-block;
	margin-right: 0.8rem;
	padding: 0;
`;
const RelatedBannerLink = styled(Link)`
	font-weight: bold;
	color: ${hemelblauw.grijscontrast};
`;

export const RelatedSubjectsBanner = ({
	text,
	linkList,
}: {
	text: string;
	linkList: Array<LinkType>;
}) => (
	<RelatedBannerBackground>
		<MainRowLayout
			mainSlot={
				<Box>
					<RelatedBannerText>{text}</RelatedBannerText>
					<RelatedBannerList>
						{linkList.map((relatedLink, key) => (
							<RelatedBannerListItem key={key}>
								<RelatedBannerLink
									linkType="relatedToLink"
									to={relatedLink.href}
								>
									{relatedLink.text}
								</RelatedBannerLink>
							</RelatedBannerListItem>
						))}
					</RelatedBannerList>
				</Box>
			}
		/>
	</RelatedBannerBackground>
);
