import styled from 'styled-components';

import { useScrollAndFocusOnMount } from '@dop/shared/hooks/useFocusOnMount';

import {
	shouldFocusRadioInput,
	WasteRadio,
	WasteRadioGroup,
} from './ui/WasteRadio';
import { StageContainer, Question } from './WasteQuestion';
import { WasteQuestionError } from './WasteQuestionError';
import { useFieldValidation } from './useFieldValidation';
import { WasteInputContainer } from './ui/WasteInputContainer';
import { WasteQuestionFaqLink } from './WasteQuestionFaqLink';
import { WasteGuidePageData, WasteGuideState } from './wasteGuide.types';
import { WasteGuideActions } from './reducers/wasteGuideReducer';

const WasteQuestionFaqLinkStyled = styled(WasteQuestionFaqLink)`
	margin-top: 1rem;
`;

export const QuestionWasteVolumeForMediumLot = ({
	questionNumber,
	formRef,
	question,
	answers,
	selection,
	wasteVolumeForMediumLotChecked,
}: {
	questionNumber: number;
	formRef: React.RefObject<HTMLFormElement>;
	question: WasteGuidePageData['companyQuestions']['wasteVolume'];
	answers: WasteGuidePageData['companyAnswers']['wasteVolumeForMediumLot'];
	selection: WasteGuideState['wasteVolumeForMediumLot'];
	wasteVolumeForMediumLotChecked: WasteGuideActions['wasteVolumeForMediumLotChecked'];
}) => {
	const questionId = 'waste-guide-question-waste-volume-for-medium-lot';
	const errorId = `${questionId}-error`;
	const {
		showError,
		inputProps: { onInvalid, onChange },
	} = useFieldValidation(formRef);
	const { scrollRef, focusRef } = useScrollAndFocusOnMount<
		HTMLDivElement,
		HTMLInputElement
	>();

	const { title, text, example, aside, faqText, faqLink, errorMessage } =
		question;
	return (
		<StageContainer ref={scrollRef}>
			<WasteRadioGroup aria-labelledby={questionId}>
				<Question
					id={questionId}
					questionNumber={questionNumber}
					title={title}
					text={text}
					example={example}
					aside={aside}
				/>
				<WasteInputContainer>
					{answers.map(({ slug, label }, index) => {
						return (
							<WasteRadio
								key={slug}
								ref={
									shouldFocusRadioInput({ selection, value: slug, index })
										? focusRef
										: undefined
								}
								name={questionId}
								value={slug}
								id={`${questionId}-${slug}`}
								data-ui-test={`${questionId}-${slug}`}
								required
								aria-describedby={errorId}
								checked={slug === selection}
								onInvalid={onInvalid}
								onChange={(event) => {
									onChange(event);
									wasteVolumeForMediumLotChecked(slug);
								}}
							>
								{label}
							</WasteRadio>
						);
					})}
				</WasteInputContainer>
				{faqLink != null && faqText != null && (
					<WasteQuestionFaqLinkStyled faqText={faqText} faqLink={faqLink} />
				)}
				<WasteQuestionError showError={showError} id={errorId}>
					{errorMessage}
				</WasteQuestionError>
			</WasteRadioGroup>
		</StageContainer>
	);
};
