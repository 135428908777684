import { Markup } from '@dop/shared/components/markup/Markup';
import { Heading } from '@dop/ui-composites/typography/Heading';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { Stack } from '@dop/ui-primitives/layout/Stack';

import { CollapseContent } from './ui/CollapseContent';
import { HTMLContent } from '@/sections/markup/Markup.types';
import {
	WasteGuidePageData,
	WasteTitleSuffixKey,
	wasteCategoryKeyMapping,
} from './wasteGuide.types';
import { Fragment } from 'react';

const WasteTypeList = ({
	wasteSlugList,
	wasteTypeTitleSuffixKey,
	wastesMap,
}: {
	wasteSlugList: Array<string>;
	wasteTypeTitleSuffixKey: WasteTitleSuffixKey;
	wastesMap: WasteGuidePageData['wastes'];
}) => {
	return (
		<ul
			css={`
				margin-bottom: 0;
			`}
		>
			{wasteSlugList.map((wasteSlug) => {
				const waste = wastesMap[wasteSlug];

				if (waste == null) return null;

				const title = waste.title;
				const description = waste.description;
				const titleSuffix =
					wasteTypeTitleSuffixKey != null
						? waste[wasteTypeTitleSuffixKey]
						: undefined;

				return description != null ? (
					<li key={wasteSlug}>
						<CollapseContent
							id={`collapse-content-${wasteSlug}`}
							buttonText={
								<span>
									<strong>{title}</strong>
									{Boolean(titleSuffix) && (
										<span>
											{' – '}
											{titleSuffix}
										</span>
									)}
								</span>
							}
						>
							<Markup html={description.html} />
						</CollapseContent>
					</li>
				) : (
					<li key={wasteSlug}>
						<strong>{title}</strong>
						{Boolean(titleSuffix) && (
							<span>
								{' – '}
								{titleSuffix}
							</span>
						)}
					</li>
				);
			})}
		</ul>
	);
};

const WasteTypeSubcategory = ({
	title,
	text,
	wastes,
	wasteTypeTitleSuffixKey,
	wastesMap,
}: {
	title: string;
	text?: HTMLContent;
	wastes: Array<string>;
	wasteTypeTitleSuffixKey: WasteTitleSuffixKey;
	wastesMap: WasteGuidePageData['wastes'];
}) => {
	return (
		<Stack key={title} $gap={1}>
			<Heading as={'h2'} level={3}>
				{title}
			</Heading>
			{text != null && <Markup html={text.html} />}
			<WasteTypeList
				wasteSlugList={wastes}
				wasteTypeTitleSuffixKey={wasteTypeTitleSuffixKey}
				wastesMap={wastesMap}
			/>
		</Stack>
	);
};

export const WasteGuideIntroductionAsidePanel = ({
	wasteAnswers,
	answersKeyArray,
	title,
	description,
	wasteTypeTitleSuffixKey,
	wastesMap,
}: {
	wasteTypeTitleSuffixKey: WasteTitleSuffixKey;
	title: string;
	description?: HTMLContent;
	wastesMap: WasteGuidePageData['wastes'];
	wasteAnswers: WasteGuidePageData['wasteAnswers'];
	answersKeyArray: typeof wasteCategoryKeyMapping[keyof typeof wasteCategoryKeyMapping &
		string]['answers'];
}) => {
	return (
		<Box $padding={[0, 2, 0, 0]}>
			<Stack $gap={2}>
				<Heading as={'h1'} level={2}>
					{title}
				</Heading>
				<Stack $gap={1}>
					{description != null && <Markup html={description.html} />}
					{answersKeyArray.map((answerKey) => {
						if (answerKey === 'alwaysSeparateWasteAnswers') {
							const subcategories = wasteAnswers.alwaysSeparateWasteAnswers;
							return (
								<Fragment key={answerKey}>
									{subcategories.map((subcategory) => {
										return (
											<WasteTypeSubcategory
												key={subcategory.slug}
												{...subcategory}
												wastesMap={wastesMap}
												wasteTypeTitleSuffixKey={wasteTypeTitleSuffixKey}
											/>
										);
									})}
								</Fragment>
							);
						}

						return (
							<WasteTypeList
								key={answerKey}
								wasteSlugList={wasteAnswers[answerKey]}
								wasteTypeTitleSuffixKey={wasteTypeTitleSuffixKey}
								wastesMap={wastesMap}
							/>
						);
					})}
				</Stack>
			</Stack>
		</Box>
	);
};
