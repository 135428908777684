import styled, { css } from 'styled-components';

import { mq } from '@dop/shared/styleHelpers/mediaQueries';
import { grijs, hemelblauw } from 'moduleAlias/project/src/styles/colors';
import { fontSize } from 'moduleAlias/project/src/styles/mixins/fontSize';

export const wasteMatrixTableBreakpoint = 'smallMedium';
export const Table = styled.table.attrs(() => ({ role: 'table' }))`
	border-spacing: 1rem 0.6rem;
	/* Negative margin to compensate for border-spacing on outide of table */
	margin: -0.6rem -1rem;
	border-collapse: separate;

	${mq({ to: wasteMatrixTableBreakpoint })`
        border-spacing: 0.5rem 0.6rem;
        /* Negative margin to compensate for border-spacing on outide of table */
        margin: -0.6rem -0.5rem;
    `}
`;
export const Tbody = styled.tbody``;
export const Tr = styled.tr``;

const cellStyle = css`
	vertical-align: baseline;
`;

export const GroupTh = styled.th.attrs(() => ({
	scope: 'col',
	colSpan: 2,
}))<{ $index: number }>`
	${cellStyle};
	text-align: left;
	color: ${hemelblauw.darkest};
	font-size: 1.1rem;
	line-height: 1.2;
	font-weight: bold;
	${({ $index }) =>
		$index > 0 &&
		css`
			padding-top: 1.5rem;
		`};
`;

export const ColumnTh = styled.th.attrs(() => ({
	scope: 'col',
}))`
	${cellStyle};
	font-weight: bold;
	${fontSize('small')};
	text-align: left;
	border-bottom: 1px solid ${grijs.default};
	color: ${hemelblauw.darkest};
`;

export const WasteTh = styled.th.attrs(() => ({
	scope: 'row',
}))<{ $index: number }>`
	${cellStyle};
	font-weight: inherit;
	font-size: inherit;
	text-align: left;
	vertical-align: middle;

	${({ $index }) =>
		$index > 0 &&
		mq({ to: wasteMatrixTableBreakpoint })`
        padding-top: 1rem;
    `};
`;

export const WasteTd = styled.td<{ $index: number }>`
	${cellStyle};
	${({ $index }) =>
		$index > 0 &&
		mq({ to: wasteMatrixTableBreakpoint })`
        	padding-top: 1rem;
    	`};
`;
