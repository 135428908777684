import React from 'react';
import { useRouter } from 'next/navigation';
import { isObject } from '@dop/shared/helpers/functional';

import {
	ANSWERS,
	WasteGuidePageData,
	WasteGuideState,
} from './wasteGuide.types';
import {
	PARAMS,
	MULTIPLE_VALUES_PARAMS,
	setWasteGuideParams,
	clearWasteGuideParams,
	parseWasteGuideParams,
	mergeAlwaysSeparateAnswerGroups,
} from './helpers/selectionUrlParams';
import { WasteGuideActions } from './reducers/wasteGuideReducer';
import { includesResultStage } from './reducers/wasteGuideStageReducer';
import { objectEntries, typeGuard } from 'tsafe';

// eslint-disable-next-line complexity
export const dispatchAnswerSelections = (
	answers: ReturnType<typeof mergeAlwaysSeparateAnswerGroups>,
	actions: WasteGuideActions,
	wasteAnswerOptions: WasteGuidePageData['wasteAnswers']
) => {
	// map which action should be called on which question when answered
	// the order of these questions reflects the order in the UI. If the UI-order changes, it should be reflected here.
	const QUESTION_ACTION_MAPPING = {
		LOT_SIZE: actions.lotSizeChecked,
		WASTE_VOLUME_FOR_MEDIUM_LOT: actions.wasteVolumeForMediumLotChecked,
		WASTE_VOLUME_FOR_LARGE_LOT: actions.wasteVolumeForLargeLotChecked,
		FREQUENT_WASTE_FOR_MEDIUM_LOT: actions.frequentWasteForMediumCompanyChecked,
		INCIDENTAL_WASTE: actions.incidentalWasteChecked,
		FREQUENT_WASTE_FOR_LARGE_LOT: actions.frequentWasteForLargeCompanyChecked,
		ALWAYS_SEPARATE_WASTE: actions.alwaysSeparateWasteChecked,
	} as const;
	const frequentWasteForMediummLotAnswerOptions =
		wasteAnswerOptions.frequentWasteForMediumCompanyAnswers;

	let tryAdvancingToNextStage = true;

	// We'll loop through all the possible questions and their answers (in the order in which they are shown in the UI).
	for (const [question, actionFn] of objectEntries(QUESTION_ACTION_MAPPING)) {
		const action = actionFn as (
			payload: string | { slug: string; value: string }
		) => void;
		const answer = answers[question];
		// Step 1 - try to enter the answer(s):
		if (!answer) continue;

		try {
			// only allow lot-size follow-up questions to be enterd with correct lot-size
			if (
				(question === 'WASTE_VOLUME_FOR_MEDIUM_LOT' &&
					answers['LOT_SIZE'] !== ANSWERS.LOT_SIZE.MEDIUM) ||
				(question === 'WASTE_VOLUME_FOR_LARGE_LOT' &&
					answers['LOT_SIZE'] !== ANSWERS.LOT_SIZE.LARGE)
			) {
				throw new Error(
					`Can not set WasteVolume for ${answers['LOT_SIZE']} lot for different lot-size`
				);
			}
			// specifically check frequent-waste-for-medium-lot:
			// this is a required radio with unknown values coming from Tridion, so the wasteguide stage reducer does not do validation on the answer:
			if (question === 'FREQUENT_WASTE_FOR_MEDIUM_LOT') {
				if (
					!(typeof answer === 'string') ||
					!frequentWasteForMediummLotAnswerOptions.includes(answer)
				) {
					tryAdvancingToNextStage = false;
					throw new Error(
						`Invalid answer for ${question}. Valid options are: ${frequentWasteForMediummLotAnswerOptions.toString()}`
					);
				}
			}

			// dispatch answers for checkboxes, matrix or radioboxes
			if (
				typeGuard<keyof typeof PARAMS>(question, question in PARAMS) &&
				MULTIPLE_VALUES_PARAMS.includes(
					PARAMS[question] as typeof MULTIPLE_VALUES_PARAMS[number]
				) &&
				Array.isArray(answer) &&
				answer.length > 0
			) {
				// Checkbox: enter multiple values and validate that this is indeed an array of answers
				answer.forEach((answer) => {
					action(answer);
				});
			} else if (question === 'ALWAYS_SEPARATE_WASTE' && isObject(answer)) {
				// Matrix: enter always-separate-answers (double check the answer is an object)
				Object.entries(answer).forEach(([slug, value]) => {
					action({ slug, value });
				});
			} else if (typeof answer === 'string') {
				// Radio: enter single value
				action(answer);
			} else {
				// no answer; don't continue in the loop though - this could be a checkbox list with no selected values
			}
		} catch (e) {
			console.warn(
				`Encountered error in trying to answer question ${question}:`,
				e
			);
			// the answer-actions don't actually throw errors yet, but might in the future due to some strict validation.
			// This should not stop the app if the answer was provided by the search-params so we catch it
			continue;
		}

		// Step 2 - try to advance to the next stage:
		if (tryAdvancingToNextStage) {
			try {
				actions.questionSubmitted();
			} catch (e) {
				console.warn(
					'Stopped trying to advance to next step while processing url-params, because of:',
					e
				);
				tryAdvancingToNextStage = false;
				// an inability to advance to the next step is usually fatal to continue with processing the answers from the search-query
				// we'll just continue with the loop, since that will pre-fill in the answers when we manually reach those steps
			}
		}
	}
};

// This hook has two functions:
// - 1st useEffect (on the first render)
//   - check if seach-params exist; fill in the answers and advance through the wasteguide-steps
// - 2nd useEffect (on switching results to showing or not-showing)
//   - if results are now showing: add the state of answers as search-params in the URL
//   - if results are now hidden (when an answer is edited): remove the search-params
export const useSelectionUrlParams = ({
	wasteGuideState,
	wasteAnswers,
	actions,
}: {
	wasteGuideState: WasteGuideState;
	wasteAnswers: WasteGuidePageData['wasteAnswers'];
	actions: WasteGuideActions;
}) => {
	const router = useRouter();
	const prevResultsShowing = React.useRef(false);

	// check on first render if we have search-params and process answers
	React.useEffect(() => {
		const answersRaw = parseWasteGuideParams();

		if (answersRaw.COMPLETED === '1') {
			const answers = mergeAlwaysSeparateAnswerGroups(
				answersRaw,
				wasteAnswers.alwaysSeparateWasteAnswers
			);
			// start the guide
			actions.wasteGuideStarted();
			dispatchAnswerSelections(answers, actions, wasteAnswers);
		}
	}, [wasteAnswers, actions]);

	// react to switching between showing/hiding results
	React.useEffect(() => {
		const resultsShowing = includesResultStage(wasteGuideState.stages);

		if (resultsShowing && !prevResultsShowing.current) {
			// update URL to reflect choices on submit
			setWasteGuideParams(wasteGuideState, router);
		} else if (!resultsShowing && prevResultsShowing.current) {
			// results are hidden by changing an answer
			clearWasteGuideParams(router);
		}
		prevResultsShowing.current = resultsShowing;
	}, [router, wasteGuideState]);
};
