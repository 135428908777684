import styled from 'styled-components';

import { hemelblauw } from 'moduleAlias/project/src/styles/colors';
import { Heading } from 'moduleAlias/project/src/components/display/heading';
import { Markup } from '@dop/shared/components/markup/Markup';
import { IconImportantSquare } from '@dop/ui-icons/svg/importantSquare';
import { translate } from '@dop/shared/translate/translate';
import { WasteGuidePageData } from './wasteGuide.types';
import { HTMLContent } from '@/sections/markup/Markup.types';
import { ReactNode } from 'react';

const BlockLayout = styled.div`
	display: grid;
	grid-template-columns: minmax(0, 2.5rem) minmax(0, 1fr);
`;

const BlockTitle = ({ children }: { children: ReactNode }) => {
	return (
		<Heading
			level={3}
			styleLevel={4}
			css={`
				margin-top: 0;
			`}
		>
			{children}
		</Heading>
	);
};

const WasteAlertBlock = ({
	title,
	text,
}: {
	title: string;
	text?: HTMLContent;
}) => {
	return (
		<BlockLayout>
			<IconImportantSquare
				role="img"
				aria-label={translate('important')}
				css={`
					margin-top: -0.4rem;
					padding: 0 0.3rem;
					align-self: flex-start;
					height: auto;
					fill: ${hemelblauw.grijscontrast};
				`}
			/>
			<div>
				<BlockTitle>{title}</BlockTitle>
				{text != null && <Markup html={text.html} />}
			</div>
		</BlockLayout>
	);
};

const WasteInfoBlock = ({
	title,
	text,
}: {
	title: string;
	text?: HTMLContent;
}) => {
	return (
		<BlockLayout>
			<div
				css={`
					background-color: ${hemelblauw.grijscontrast};
					background-clip: content-box;
					padding: 0.2rem 1.1rem 0.3rem 1.1rem;
				`}
			></div>
			<div>
				<BlockTitle>{title}</BlockTitle>
				{text != null && <Markup html={text.html} />}
			</div>
		</BlockLayout>
	);
};

export const WasteResultExplanations = ({
	explanations,
}: {
	explanations: WasteGuidePageData['wasteResults']['explanations'];
}) => {
	return (
		<div
			css={`
				display: grid;
				grid-gap: 2.5rem;
				margin-top: 2.5rem;
			`}
		>
			{explanations.map(({ type, title, text }, index) => {
				switch (type) {
					case 'alert':
						return <WasteAlertBlock key={index} title={title} text={text} />;
					case 'info':
					default:
						return <WasteInfoBlock key={index} title={title} text={text} />;
				}
			})}
		</div>
	);
};
