import { RefObject } from 'react';
import styled from 'styled-components';

import { useScrollAndFocusOnMount } from '@dop/shared/hooks/useFocusOnMount';

import {
	shouldFocusRadioInput,
	WasteRadio,
	WasteRadioGroup,
} from './ui/WasteRadio';
import { StageContainer, Question } from './WasteQuestion';
import { WasteQuestionError } from './WasteQuestionError';
import { WasteItemInfoPopupButton } from './WasteItemInfoPopup';
import { useFieldValidation } from './useFieldValidation';
import { WasteInputContainer } from './ui/WasteInputContainer';
import { WasteQuestionFaqLink } from './WasteQuestionFaqLink';
import { WasteGuidePageData, WasteGuideState } from './wasteGuide.types';
import { WasteGuideActions } from './reducers/wasteGuideReducer';

const WasteQuestionFaqLinkStyled = styled(WasteQuestionFaqLink)`
	margin-top: 1rem;
`;

export const QuestionFrequentWasteForMediumCompany = ({
	questionNumber,
	formRef,
	question,
	answers,
	selection,
	frequentWasteForMediumCompanyChecked,
	wastesMap,
}: {
	questionNumber: number;
	formRef: RefObject<HTMLFormElement>;
	question: WasteGuidePageData['wasteQuestionsForMediumCompany']['frequentWaste'];
	answers: WasteGuidePageData['wasteAnswers']['frequentWasteForMediumCompanyAnswers'];
	selection: WasteGuideState['frequentWasteForMediumCompany'];
	frequentWasteForMediumCompanyChecked: WasteGuideActions['frequentWasteForMediumCompanyChecked'];
	wastesMap: WasteGuidePageData['wastes'];
}) => {
	const questionId = 'waste-guide-question-frequent-waste-for-medium-company';
	const errorId = `${questionId}-error`;
	const {
		showError,
		inputProps: { onInvalid, onChange },
	} = useFieldValidation(formRef);
	const { scrollRef, focusRef } = useScrollAndFocusOnMount<
		HTMLDivElement,
		HTMLInputElement
	>();
	const { title, text, example, aside, faqText, faqLink, errorMessage } =
		question;

	return (
		<StageContainer ref={scrollRef}>
			<WasteRadioGroup aria-labelledby={questionId}>
				<Question
					id={questionId}
					questionNumber={questionNumber}
					title={title}
					text={text}
					example={example}
					aside={aside}
				/>
				<WasteInputContainer>
					{answers.map((slug, index) => {
						const title = wastesMap[slug]?.title;

						if (title == null) return null;

						return (
							<div
								key={slug}
								css={`
									display: flex;
									align-items: flex-start;
								`}
							>
								<WasteRadio
									ref={
										shouldFocusRadioInput({ selection, value: slug, index })
											? focusRef
											: undefined
									}
									name={questionId}
									id={`${questionId}-${slug}`}
									data-ui-test={`${questionId}-${slug}`}
									value={slug}
									checked={slug === selection}
									required
									aria-describedby={errorId}
									onInvalid={onInvalid}
									onChange={(event) => {
										onChange(event);
										frequentWasteForMediumCompanyChecked(slug);
									}}
								>
									{title}
								</WasteRadio>
								<WasteItemInfoPopupButton
									slug={slug}
									questionId={questionId}
									wastesMap={wastesMap}
								/>
							</div>
						);
					})}
				</WasteInputContainer>
				{faqLink != null && faqText != null && (
					<WasteQuestionFaqLinkStyled faqText={faqText} faqLink={faqLink} />
				)}
				<WasteQuestionError showError={showError} id={errorId}>
					{errorMessage}
				</WasteQuestionError>
			</WasteRadioGroup>
		</StageContainer>
	);
};
