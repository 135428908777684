import { ReactNode } from 'react';

import { hemelblauw } from 'moduleAlias/project/src/styles/colors';
import { noBullets, noListSpacing } from '@dop/shared/styleHelpers/list';
import { Heading } from 'moduleAlias/project/src/components/display/heading';
import { Markup } from '@dop/shared/components/markup/Markup';
import { HTMLContent } from '@/sections/markup/Markup.types';

export const WasteResultsWasteItem = ({
	title,
	resultExplanation,
	testid,
}: {
	title: ReactNode;
	resultExplanation?: HTMLContent;
	testid?: string;
}) => {
	return (
		<li
			css={`
				color: ${hemelblauw.grijscontrast};
				font-weight: bold;
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
			`}
		>
			<Heading
				level={4}
				data-ui-test={testid}
				css={`
					color: inherit;
					margin: 0;
				`}
			>
				{title}
			</Heading>
			{resultExplanation != null && (
				<Markup
					html={resultExplanation.html}
					css={`
						font-weight: normal;
						&:after {
							content: '';
							display: block;
							margin-top: 1rem;
							margin-bottom: -1rem;
						}
					`}
				/>
			)}
		</li>
	);
};

export const WasteResultsWasteGroupEmpty = ({
	noResultText,
}: {
	noResultText: string;
}) => {
	return <Heading level={3}>{noResultText}</Heading>;
};

export const WasteResultsWasteGroup = ({
	title,
	text,
	children,
}: {
	title: string;
	text: HTMLContent;
	children: ReactNode;
}) => {
	return (
		<div>
			<Heading level={3}>{title}</Heading>
			<Markup html={text.html} />
			<ul
				css={`
					${noBullets};
					${noListSpacing};
					padding: 0.5rem 1rem 0.5rem 2.5rem;
					margin-top: 1.5rem;
					background-color: white;
				`}
			>
				{children}
			</ul>
		</div>
	);
};
