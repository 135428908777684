import { config } from 'moduleAlias/config';
import environmentConfig from 'moduleAlias/environmentConfig';

import { pipe } from '../helpers/functional';
import { parseUrl } from '../helpers/link';

const ensureTrailingSlash = (pathname) => {
	return pathname.endsWith('/') ? pathname : `${pathname}/`;
};

const stripDoubleSlashes = (pathname) => {
	return pathname.replace(/\/{2,}/g, '/');
};

const isSearchPath = (path) => {
	const { pathname } = new URL(path, environmentConfig.originUrl);
	return pathname.startsWith(config.searchPath);
};

const pathnameToLowerCase = (pathname) => {
	if (isSearchPath(pathname)) {
		const pathnameComponents = pathname.split('/');

		const queryKeyIndex = pathnameComponents.indexOf(config.searchParamQuery);

		if (queryKeyIndex < 0) {
			return pathname.toLowerCase();
		}

		const queryValueIndex = queryKeyIndex + 1;

		const lowerCasePathnameComponents = pathnameComponents.map(
			(pathnameComponent, index) => {
				return queryValueIndex === index
					? pathnameComponent
					: pathnameComponent.toLowerCase();
			}
		);

		const lowerCasePathname = lowerCasePathnameComponents.join('/');

		return lowerCasePathname;
	} else {
		return pathname.toLowerCase();
	}
};

export const normalizePathname = pipe(
	stripDoubleSlashes,
	ensureTrailingSlash,
	pathnameToLowerCase
);

export const urlNormalizer = (url) => {
	const parsedUrl = parseUrl(url);

	const normalizedPathname = normalizePathname(parsedUrl.pathname);

	if (normalizedPathname !== parsedUrl.pathname) {
		const destinationUrl = `${normalizedPathname || ''}${
			parsedUrl.search || ''
		}${parsedUrl.hash || ''}`;

		return { redirecting: true, destinationUrl };
	}

	return {
		redirecting: false,
		destinationUrl: `${parsedUrl.pathname || ''}${parsedUrl.search || ''}${
			parsedUrl.hash || ''
		}`,
	};
};
