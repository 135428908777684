import { Link } from 'moduleAlias/project/src/components/link/link';
import { LinkType } from '@/globals/webApiTypes/common.types';

export const WasteQuestionFaqLink = ({
	className,
	faqText,
	faqLink,
}: {
	className?: string;
	faqText: string;
	faqLink: LinkType;
}) => {
	return (
		<p
			className={className}
			css={`
				margin-bottom: 0;
			`}
		>
			{faqText}{' '}
			<Link newWindow to={faqLink.href}>
				{faqLink.text}
			</Link>
		</p>
	);
};
