import {
	WasteResultsWasteGroup,
	WasteResultsWasteGroupEmpty,
	WasteResultsWasteItem,
} from './WasteResultsWasteGroup';
import { WasteGuidePageData } from './wasteGuide.types';

export const IncidentalWasteResults = ({
	incidentalWasteSlugs,
	incidentalWasteResults,
	wastesMap,
}: {
	incidentalWasteSlugs: Set<string>;
	incidentalWasteResults: WasteGuidePageData['wasteResults']['incidentalWasteResults'];
	wastesMap: WasteGuidePageData['wastes'];
}) => {
	const { title, text, noResultText } = incidentalWasteResults;

	if (incidentalWasteSlugs.size === 0) {
		return <WasteResultsWasteGroupEmpty noResultText={noResultText} />;
	}

	return (
		<WasteResultsWasteGroup title={title} text={text}>
			{[...incidentalWasteSlugs].map((wasteSlug) => {
				const wasteItem = wastesMap[wasteSlug];

				return (
					<WasteResultsWasteItem
						key={wasteSlug}
						testid={`${wasteSlug}-incidentalWaste`}
						title={
							<>
								{wasteItem.title}
								{wasteItem.separateForVolume != null && (
									<span
										css={`
											font-style: italic;
											font-weight: normal;
										`}
									>
										{' – '}
										{wasteItem.separateForVolume}
									</span>
								)}
							</>
						}
						resultExplanation={wasteItem.resultExplanation}
					/>
				);
			})}
		</WasteResultsWasteGroup>
	);
};
