import { wasteGuideConfig } from 'moduleAlias/project/src/pages/wasteGuide/wasteGuideConfig';

import {
	FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE,
	FREQUENT_WASTE_FOR_LARGE_COMPANY_STAGE,
	WasteGuideState,
	WasteGuidePageData,
} from './wasteGuide.types';

// Either frequent waste question for medium or large company is showing for the user.
// We need the selection from the active question in order to show data from the active question path.
const getFrequentWasteSelection = (wasteGuideState: WasteGuideState) => {
	const { stages } = wasteGuideState;
	if (stages.includes(FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE)) {
		return new Set([wasteGuideState.frequentWasteForMediumCompany]);
	}
	if (stages.includes(FREQUENT_WASTE_FOR_LARGE_COMPANY_STAGE)) {
		return wasteGuideState.frequentWasteForLargeCompany;
	}

	return new Set();
};

// Either frequent waste question for medium or large company is showing for the user.
// We need the answers from the active question in order to show data from the active question path.
const getFrequentWasteAnswers = ({
	stages,
	wasteGuidePageData,
}: {
	stages: WasteGuideState['stages'];
	wasteGuidePageData: WasteGuidePageData;
}) => {
	if (stages.includes(FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE)) {
		return wasteGuidePageData.wasteAnswers.frequentWasteForMediumCompanyAnswers;
	}
	if (stages.includes(FREQUENT_WASTE_FOR_LARGE_COMPANY_STAGE)) {
		return wasteGuidePageData.wasteAnswers.frequentWasteForLargeCompanyAnswers;
	}

	return [];
};

export const getFrequentWasteSlugs = ({
	wasteGuideState,
	wasteGuidePageData,
}: {
	wasteGuideState: WasteGuideState;
	wasteGuidePageData: WasteGuidePageData;
}) => {
	const selection = getFrequentWasteSelection(wasteGuideState);
	const answers = getFrequentWasteAnswers({
		stages: wasteGuideState.stages,
		wasteGuidePageData,
	});

	const slugs = answers.filter((answerSlug) => selection.has(answerSlug));

	return slugs;
};

// For the incidental waste question we don't want to show wastes already
// selected by the user during the frequent waste question,
// because if the user already has a contract with a waste company
// for this waste kind on a regular basis
// he doesn't need to concern himself with a separate contract
// for incidental waste. So we remove those options from the answer list here.
// Either frequent waste question for medium or large company is showing for the user.
// We need the answers from the active question in order to show data from the active question path.
export const getIncidentaWasteAnswers = ({
	wasteGuideState,
	wasteGuidePageData,
}: {
	wasteGuideState: WasteGuideState;
	wasteGuidePageData: WasteGuidePageData;
}) => {
	const allIncidentalWasteAnswers =
		wasteGuidePageData.wasteAnswers.incidentalWasteAnswers;
	const { stages } = wasteGuideState;

	if (stages.includes(FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE)) {
		const frequentWasteForMediumCompany =
			wasteGuideState.frequentWasteForMediumCompany;

		return allIncidentalWasteAnswers.filter((slug) => {
			return slug !== frequentWasteForMediumCompany;
		});
	}
	if (stages.includes(FREQUENT_WASTE_FOR_LARGE_COMPANY_STAGE)) {
		const frequentWasteForLargeCompany =
			wasteGuideState.frequentWasteForLargeCompany;
		return allIncidentalWasteAnswers.filter((slug) => {
			return !frequentWasteForLargeCompany.has(slug);
		});
	}

	return allIncidentalWasteAnswers;
};

export const getIncidentalWasteSlugs = ({
	wasteGuideState,
	wasteGuidePageData,
}: {
	wasteGuideState: WasteGuideState;
	wasteGuidePageData: WasteGuidePageData;
}) => {
	const selection = wasteGuideState.incidentalWaste;
	const answers = getIncidentaWasteAnswers({
		wasteGuideState,
		wasteGuidePageData,
	});

	const slugs = answers.filter((answerSlug) => selection.has(answerSlug));

	return slugs;
};

const getFlatAlwaysSeparateWasteAnswers = (
	wasteGuidePageData: WasteGuidePageData
) => {
	const answerGroups =
		wasteGuidePageData.wasteAnswers.alwaysSeparateWasteAnswers;

	const answers = answerGroups.flatMap(({ wastes }) => {
		return wastes;
	});

	return answers;
};

// Either filter the wastes that are selected as 'regelmatig'/'often' or 'soms'/'sometimes'.
// This is done in order to show them in the frequent or incidental
// category in the results screen.
const getAlwaysSeparateWasteSlugs =
	(wantedSelectionValue: 'often' | 'sometimes') =>
	({
		wasteGuideState,
		wasteGuidePageData,
	}: {
		wasteGuideState: WasteGuideState;
		wasteGuidePageData: WasteGuidePageData;
	}) => {
		const selection = wasteGuideState.alwaysSeparateWaste;
		const answers = getFlatAlwaysSeparateWasteAnswers(wasteGuidePageData);

		const slugs = answers.filter((answerSlug) => {
			const selectionValue = selection[answerSlug];
			return (
				selectionValue != null &&
				selectionValue ===
					wasteGuideConfig.frequencyOptions[wantedSelectionValue].slug
			);
		});

		return slugs;
	};

export const getFrequentAlwaysSeparateWasteSlugs =
	getAlwaysSeparateWasteSlugs('often');
export const getIncidentalAlwaysSeparateWasteSlugs =
	getAlwaysSeparateWasteSlugs('sometimes');
