import React from 'react';

import { useFocusOnValueChange } from '@dop/shared/hooks/useFocusOnMount';
import {
	SideOverlay,
	SideOverlayProps,
} from '@dop/shared/components/sideOverlay/sideOverlay';
import { RequiredSlotElement } from '@dop/shared/components/pageLayout/pageLayout.types';

export const asideBreakpoint = 'smallMedium';

export const useAsidePanel = <FocusTargetElement extends HTMLElement>(
	id: string
) => {
	const [opened, setOpened] = React.useState(false);
	const focusRef = useFocusOnValueChange<boolean, FocusTargetElement>(
		opened,
		true
	);
	const onClose = () => setOpened(false);
	return {
		buttonProps: {
			ref: focusRef,
			'aria-expanded': opened,
			'aria-controls': id,
			onClick: () => {
				setOpened((opened) => !opened);
			},
		},
		panelProps: {
			opened,
			id,
			onClose,
			onBackdropClicked: onClose,
		},
	};
};

export const AsidePanelContainer = ({
	children,
	...props
}: {
	children: RequiredSlotElement;
} & Omit<SideOverlayProps, 'contentSlot'>) => (
	<SideOverlay {...props} contentSlot={children} />
);
