import { WasteGuidePageData } from './wasteGuide.types';
import {
	WasteResultsWasteItem,
	WasteResultsWasteGroup,
	WasteResultsWasteGroupEmpty,
} from './WasteResultsWasteGroup';

export const FrequentWasteResults = ({
	frequentWasteSlugs,
	frequentWasteResults,
	wastesMap,
}: {
	frequentWasteSlugs: Set<string>;
	frequentWasteResults: WasteGuidePageData['wasteResults']['frequentWasteResults'];
	wastesMap: WasteGuidePageData['wastes'];
}) => {
	const { title, text, noResultText } = frequentWasteResults;

	if (frequentWasteSlugs.size === 0) {
		return <WasteResultsWasteGroupEmpty noResultText={noResultText} />;
	}

	return (
		<WasteResultsWasteGroup title={title} text={text}>
			{[...frequentWasteSlugs].map((wasteSlug) => {
				const wasteItem = wastesMap[wasteSlug];
				return (
					<WasteResultsWasteItem
						key={wasteSlug}
						testid={`${wasteSlug}-frequentWaste`}
						title={wasteItem.title}
						resultExplanation={wasteItem.resultExplanation}
					/>
				);
			})}
		</WasteResultsWasteGroup>
	);
};
