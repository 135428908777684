import React, { useImperativeHandle } from 'react';
import styled from 'styled-components';

import { useScrollAndFocusOnMount } from '@dop/shared/hooks/useFocusOnMount';

import {
	WasteRadio,
	WasteRadioGroup,
	shouldFocusRadioInput,
} from './ui/WasteRadio';
import { StageContainer, Question } from './WasteQuestion';
import { WasteQuestionError } from './WasteQuestionError';
import { WasteQuestionFaqLink } from './WasteQuestionFaqLink';
import { useFieldValidation } from './useFieldValidation';
import { WasteInputContainer } from './ui/WasteInputContainer';
import { WasteGuidePageData, WasteGuideState } from './wasteGuide.types';
import { WasteGuideActions } from './reducers/wasteGuideReducer';

const WasteQuestionFaqLinkStyled = styled(WasteQuestionFaqLink)`
	margin-top: 1rem;
`;

export const QuestionLotSize = ({
	questionNumber,
	formRef,
	firstQuestionScrollRef,
	firstQuestionFocusRef,
	question,
	answers,
	selection,
	lotSizeChecked,
}: {
	questionNumber: number;
	formRef: React.RefObject<HTMLFormElement>;
	firstQuestionScrollRef: React.RefObject<HTMLDivElement | null>;
	firstQuestionFocusRef: React.RefObject<HTMLInputElement | null>;
	answers: WasteGuidePageData['companyAnswers']['lotSize'];
	question: WasteGuidePageData['companyQuestions']['lotSize'];
	selection: WasteGuideState['lotSize'];
	lotSizeChecked: WasteGuideActions['lotSizeChecked'];
}) => {
	const questionId = 'waste-guide-question-lot-size';
	const errorId = `${questionId}-error`;
	const {
		showError,
		inputProps: { onInvalid, onChange },
	} = useFieldValidation(formRef);

	const { scrollRef, focusRef } = useScrollAndFocusOnMount<
		HTMLDivElement,
		HTMLInputElement
	>();

	const { title, text, aside, example, faqText, faqLink, errorMessage } =
		question;

	useImperativeHandle(
		firstQuestionFocusRef,
		() => {
			return focusRef.current;
		},
		[focusRef]
	);

	useImperativeHandle(
		firstQuestionScrollRef,
		() => {
			return scrollRef.current;
		},
		[scrollRef]
	);

	return (
		<StageContainer ref={scrollRef}>
			<WasteRadioGroup aria-labelledby={questionId}>
				<Question
					id={questionId}
					questionNumber={questionNumber}
					title={title}
					text={text}
					aside={aside}
					example={example}
				/>
				<WasteInputContainer>
					{answers.map(({ slug, label }, index) => {
						return (
							<WasteRadio
								key={slug}
								ref={
									shouldFocusRadioInput({ selection, value: slug, index })
										? focusRef
										: undefined
								}
								name={questionId}
								value={slug}
								id={`${questionId}-${slug}`}
								data-ui-test={`${questionId}-${slug}`}
								checked={slug === selection}
								required
								aria-describedby={errorId}
								onInvalid={onInvalid}
								onChange={(event) => {
									onChange(event);
									lotSizeChecked(slug);
								}}
							>
								{label}
							</WasteRadio>
						);
					})}
				</WasteInputContainer>
				{faqLink != null && faqText != null && (
					<WasteQuestionFaqLinkStyled faqText={faqText} faqLink={faqLink} />
				)}

				<WasteQuestionError showError={showError} id={errorId}>
					{errorMessage}
				</WasteQuestionError>
			</WasteRadioGroup>
		</StageContainer>
	);
};
