import React from 'react';

import { translate } from '@dop/shared/translate/translate';

import {
	FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE,
	INCIDENTAL_WASTE_STAGE,
	LOT_SIZE_STAGE,
	WASTE_VOLUME_FOR_LARGE_LOT_STAGE,
	WASTE_VOLUME_FOR_MEDIUM_LOT_STAGE,
	FREQUENT_WASTE_FOR_LARGE_COMPANY_STAGE,
	ALWAYS_SEPARATE_WASTE_STAGE,
	lastQuestionStage,
	interimResultShowingStage,
	WasteGuideState,
	WasteGuidePageData,
} from './wasteGuide.types';
import { WasteGuideSubmit } from './WasteGuideSubmit';
import { QuestionLotSize } from './QuestionLotSize';
import { QuestionWasteVolumeForMediumLot } from './QuestionWasteVolumeForMediumLot';
import { QuestionWasteVolumeForLargeLot } from './QuestionWasteVolumeForLargeLot';
import { QuestionFrequentWasteForMediumCompany } from './QuestionFrequentWasteForMediumCompany';
import { QuestionFrequentWasteForLargeCompany } from './QuestionFrequentWasteForLargeCompany';
import { useShowInterimResult, WasteInterimResult } from './WasteInterimResult';
import { QuestionIncidentalWaste } from './QuestionIncidentalWaste';
import { QuestionAlwaysSeparateWaste } from './QuestionAlwaysSeparateWaste';
import { useFormValidity } from './useFieldValidation';
import { useSelectionUrlParams } from './useSelectionUrlParams';
import { WasteGuideActions } from './reducers/wasteGuideReducer';
import { includesResultStage } from './reducers/wasteGuideStageReducer';

export const WasteForm = ({
	actions,
	wasteGuideState,
	pageData,
	firstQuestionScrollRef,
	firstQuestionFocusRef,
}: {
	actions: WasteGuideActions;
	wasteGuideState: WasteGuideState;
	pageData: WasteGuidePageData;
	firstQuestionScrollRef: React.RefObject<HTMLDivElement | null>;
	firstQuestionFocusRef: React.RefObject<HTMLInputElement | null>;
}) => {
	const showInterimResult = useShowInterimResult(wasteGuideState);

	const { formRef, validity } = useFormValidity(wasteGuideState);
	useSelectionUrlParams({
		wasteGuideState,
		actions,
		wasteAnswers: pageData.wasteAnswers,
	});

	return (
		<form
			css={`
				padding-bottom: 3rem;
			`}
			ref={formRef}
			noValidate
			onSubmit={(event) => {
				event.preventDefault();
				const formElement = event.currentTarget;
				if (formElement.checkValidity()) {
					actions.questionSubmitted();
				} else {
					const invalidElement = formElement.querySelector(':invalid');
					if (invalidElement instanceof HTMLElement) invalidElement.focus();
				}
			}}
		>
			{wasteGuideState.stages.map((stage, index) => {
				const questionNumber = index + 1;

				return (
					<React.Fragment key={index}>
						{stage === interimResultShowingStage && (
							<WasteInterimResult
								showInterimResult={showInterimResult}
								interimResults={pageData.interimResults}
							/>
						)}
						{(() => {
							switch (stage) {
								case LOT_SIZE_STAGE:
									return (
										<QuestionLotSize
											formRef={formRef}
											questionNumber={questionNumber}
											firstQuestionScrollRef={firstQuestionScrollRef}
											firstQuestionFocusRef={firstQuestionFocusRef}
											answers={pageData.companyAnswers.lotSize}
											question={pageData.companyQuestions.lotSize}
											selection={wasteGuideState.lotSize}
											lotSizeChecked={actions.lotSizeChecked}
										/>
									);

								case WASTE_VOLUME_FOR_MEDIUM_LOT_STAGE:
									return (
										<QuestionWasteVolumeForMediumLot
											formRef={formRef}
											questionNumber={questionNumber}
											question={pageData.companyQuestions.wasteVolume}
											answers={pageData.companyAnswers.wasteVolumeForMediumLot}
											selection={wasteGuideState.wasteVolumeForMediumLot}
											wasteVolumeForMediumLotChecked={
												actions.wasteVolumeForMediumLotChecked
											}
										/>
									);

								case WASTE_VOLUME_FOR_LARGE_LOT_STAGE:
									return (
										<QuestionWasteVolumeForLargeLot
											formRef={formRef}
											questionNumber={questionNumber}
											question={pageData.companyQuestions.wasteVolume}
											answers={pageData.companyAnswers.wasteVolumeForLargeLot}
											selection={wasteGuideState.wasteVolumeForLargeLot}
											wasteVolumeForLargeLotChecked={
												actions.wasteVolumeForLargeLotChecked
											}
										/>
									);

								case FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE:
									return (
										<QuestionFrequentWasteForMediumCompany
											formRef={formRef}
											questionNumber={questionNumber}
											question={
												pageData.wasteQuestionsForMediumCompany.frequentWaste
											}
											answers={
												pageData.wasteAnswers
													.frequentWasteForMediumCompanyAnswers
											}
											selection={wasteGuideState.frequentWasteForMediumCompany}
											frequentWasteForMediumCompanyChecked={
												actions.frequentWasteForMediumCompanyChecked
											}
											wastesMap={pageData.wastes}
										/>
									);

								case FREQUENT_WASTE_FOR_LARGE_COMPANY_STAGE:
									return (
										<QuestionFrequentWasteForLargeCompany
											questionNumber={questionNumber}
											question={
												pageData.wasteQuestionsForLargeCompany.frequentWaste
											}
											answers={
												pageData.wasteAnswers
													.frequentWasteForLargeCompanyAnswers
											}
											selection={wasteGuideState.frequentWasteForLargeCompany}
											frequentWasteForLargeCompanyChecked={
												actions.frequentWasteForLargeCompanyChecked
											}
											frequentWasteForLargeCompanyUnchecked={
												actions.frequentWasteForLargeCompanyUnchecked
											}
											wastesMap={pageData.wastes}
										/>
									);

								case INCIDENTAL_WASTE_STAGE: {
									return (
										<QuestionIncidentalWaste
											questionNumber={questionNumber}
											focusOnMount={!showInterimResult}
											showInterimResult={showInterimResult}
											question={pageData.commonWasteQuestions.incidentalWaste}
											answers={pageData.wasteAnswers.incidentalWasteAnswers}
											selection={wasteGuideState.incidentalWaste}
											stages={wasteGuideState.stages}
											frequentWasteForMediumCompany={
												wasteGuideState.frequentWasteForMediumCompany
											}
											frequentWasteForLargeCompany={
												wasteGuideState.frequentWasteForLargeCompany
											}
											incidentalWasteChecked={actions.incidentalWasteChecked}
											incidentalWasteUnchecked={
												actions.incidentalWasteUnchecked
											}
											wastesMap={pageData.wastes}
										/>
									);
								}

								case ALWAYS_SEPARATE_WASTE_STAGE:
									return (
										<QuestionAlwaysSeparateWaste
											formRef={formRef}
											questionNumber={questionNumber}
											question={
												pageData.commonWasteQuestions.alwaysSeparateWaste
											}
											answerGroups={
												pageData.wasteAnswers.alwaysSeparateWasteAnswers
											}
											selection={wasteGuideState.alwaysSeparateWaste}
											alwaysSeparateWasteChecked={
												actions.alwaysSeparateWasteChecked
											}
											wasteMap={pageData.wastes}
										/>
									);

								default:
									return null;
							}
						})()}
					</React.Fragment>
				);
			})}

			{wasteGuideState.stages.length > 0 &&
				!includesResultStage(wasteGuideState.stages) && (
					<WasteGuideSubmit
						key={wasteGuideState.stages.at(-1) /* For focus management */}
						validity={validity}
					>
						{wasteGuideState.stages.includes(lastQuestionStage)
							? translate('view your results')
							: translate('next')}
					</WasteGuideSubmit>
				)}
		</form>
	);
};
