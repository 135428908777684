import React from 'react';

import { translate } from '@dop/shared/translate/translate';
import { IconChevronDown } from '@dop/ui-icons/svg/chevronDown';
import { slugify } from '@dop/shared/helpers/slugify';
import { hemelblauw } from 'moduleAlias/project/src/styles/colors';
import { cleanButton } from 'moduleAlias/project/src/styles/mixins/button';
import { Heading } from 'moduleAlias/project/src/components/display/heading';
import { Markup } from '@dop/shared/components/markup/Markup';
import { HTMLContent } from '@/sections/markup/Markup.types';
import { Collapse } from '@/uiComposites/interactive/Collapse';

export const WasteQuestionExample = ({
	title,
	text,
}: {
	title: string;
	text: HTMLContent;
}) => {
	const descriptionId = `${slugify(title)}-example`;
	const [opened, setOpened] = React.useState(false);

	return (
		<div
			css={`
				grid-area: example;
				margin-bottom: 1rem;
			`}
		>
			<Heading
				level={3}
				styleLevel={4}
				css={`
					margin-top: 0;
					font-weight: normal;
				`}
			>
				<button
					type="button"
					css={`
						${cleanButton};
						color: ${hemelblauw.default};
						text-decoration: underline;
						display: flex;
					`}
					onClick={() => setOpened((opened) => !opened)}
					aria-controls={descriptionId}
					aria-expanded={opened}
				>
					{title}
					<div
						css={`
							display: flex;
							align-items: center;
							flex: none;
							align-items: center;
							cursor: pointer;
							transition: 350ms transform;
							transform: ${opened ? 'rotate(-180deg)' : 'rotate(0deg)'};
						`}
					>
						<IconChevronDown
							role="img"
							aria-label={opened ? translate('close') : translate('open')}
							css={`
								width: 1rem;
								height: 1rem;
								color: ${hemelblauw.default};
								fill: currentColor;
							`}
						/>
					</div>
				</button>
			</Heading>
			<Collapse id={descriptionId} isOpened={opened}>
				<Markup
					html={text.html}
					css={`
						& ul {
							// override styles for lists inside forms
							margin-left: 1rem;
						}
					`}
				/>
			</Collapse>
		</div>
	);
};
