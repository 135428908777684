import React from 'react';

import { rood } from 'moduleAlias/project/src/styles/colors';
import { addTestSelector } from '@dop/shared/helpers/testSelector';

export const WasteQuestionError = ({
	className = '',
	children,
	id,
	showError = false,
}: {
	className?: string;
	children: React.ReactNode;
	id: string;
	showError?: boolean;
}) => {
	return (
		<div
			role="alert"
			id={id}
			className={className}
			{...addTestSelector(`ui${id}`)}
		>
			{showError ? (
				<span
					css={`
						display: inline-block;
						max-width: 100%;
						left: 0;
						color: ${rood.default};
						width: fit-content;
						line-height: 1.2;
						margin-top: 0.5rem;
					`}
				>
					{children}
				</span>
			) : null}
		</div>
	);
};
