import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconCheckDik: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '3.85 2.81 17.32 17.23', width: '17.32', height: '17.23' }
			: { viewBox: '0 0 24 24', width: '24', height: '24' })}
		{...props}
	>
		<g>
			<rect fill="none" width="24" height="24" />
		</g>
		<g>
			<path
				d={`M20.6299,3.0811c-0.5645-0.417-1.3516-0.3365-1.8184,0.1855l-7.9404,8.8838L6.0977,8.0156
c-0.5049-0.4365-1.25-0.4424-1.7588-0.0136c-0.5108,0.4282-0.6338,1.1635-0.292,1.7348l5.7822,9.6499
c0.2441,0.4072,0.6836,0.6572,1.1582,0.6587c0.002,0,0.0029,0,0.0049,0c0.4726,0,0.9121-0.2466,1.1582-0.6514l8.8301-14.52
C21.3447,4.2754,21.1924,3.498,20.6299,3.0811z`}
			/>
		</g>
	</svg>
);
