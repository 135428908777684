import React, { ComponentPropsWithoutRef } from 'react';
import { darken } from 'polished';

import { hemelblauw } from 'moduleAlias/project/src/styles/colors';
import { cleanButton } from 'moduleAlias/project/src/styles/mixins/button';
import { hoverEtc } from 'moduleAlias/project/src/styles/mixins/pseudo';

export const TextButton = React.forwardRef(
	(
		{
			textColor = hemelblauw.default,
			...props
		}: { textColor?: string } & ComponentPropsWithoutRef<'button'>,
		ref: React.ForwardedRef<HTMLButtonElement>
	) => {
		const hoverColor = darken(0.07)(textColor);

		return (
			<button
				ref={ref}
				type="button"
				css={`
					${cleanButton};
					color: ${textColor};
					text-decoration: underline;
					line-height: 1.2;
					width: fit-content;
					grid-area: aside-button;
					flex: none;
					justify-self: flex-start;
					transition: color 200ms;

					${hoverEtc`
						color: ${hoverColor};
					`};
				`}
				{...props}
			/>
		);
	}
);
