import { List } from 'immutable';

import { normalizePathname } from './urlNormalizer';
import { unexisty } from './functional';

export const cleanEncodeURIComponent = (uriComponent) => {
	return unexisty(uriComponent) || Number.isNaN(uriComponent)
		? ''
		: encodeURIComponent(uriComponent);
};

// Tagged template literal to safely build pathnames
// Interpolated values are encoded by encodeURIComponent and null, undefined and NaN are treated as empty strings
// When arrays are interpolated the values are treated as separate pathname components
//
// Usage: pathnameBuilder`/evenementen/${'aantal'}/${9}/${'onderwerp'}/${['administratie','bedrijf-starten']}/${'regio'}/${'gelderland'}/`;
// Result: '/evenementen/aantal/9/onderwerp/administratie/bedrijf-starten/regio/gelderland/'
export const pathnameBuilder = (strings, ...entries) => {
	const listSeparator = '/';
	const result = [];

	for (let index = 0; index < entries.length; index += 1) {
		result.push(strings[index]);
		const entry = entries[index];

		const encodedEntry =
			Array.isArray(entry) || entry instanceof List
				? entry.map(cleanEncodeURIComponent).join(listSeparator)
				: cleanEncodeURIComponent(entry);
		result.push(encodedEntry);
	}

	result.push(strings[strings.length - 1]);

	const rawPathname = result.join('');

	const pathname = normalizePathname(rawPathname);

	return pathname;
};

// e.g. { foo: bar, baz: [1,2] } will become foo=bar&baz=1&baz=2
// we use this notation for arrays to allow easy usage of URLSearchParam.get()
// instead of a custom query-param parser (which is complicated / prone to errors)
export const convertParamsObjectToString = (params, currentParamsStr = '') => {
	const searchParams = new URLSearchParams(currentParamsStr);

	Object.entries(params).forEach(([name, value]) => {
		if (!value) {
			// no value, but make sure any existing values are removed
			searchParams.delete(name);
		} else if (Array.isArray(value)) {
			value.flat().forEach((arrayItem, i) => {
				if (i === 0) {
					// override any old values for this name for first value
					searchParams.set(name, arrayItem || '');
				} else {
					// after that, append them
					searchParams.append(name, arrayItem || '');
				}
			});
		} else {
			searchParams.set(name, value || '');
		}
	});
	return searchParams.toString();
};
