import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconPijlDelen: SvgContent = ({ cropped = false, ...props }) => {
	return (
		<svg
			{...(cropped
				? { viewBox: '0.96 4.27 29.98 23.73', width: '29.98', height: '23.73' }
				: { viewBox: '0 0 32 32', width: '32', height: '32' })}
			{...props}
		>
			<g fillRule="evenodd">
				<path d="M1,26.2685573 C0.870124916,27.026455 1.09324028,27.5485635 1.66934608,27.8348826 C2.24545188,28.1212018 2.93058593,28.0330813 3.72474823,27.5705213 C6.76295125,24.0542981 9.16557801,21.7782801 10.9326285,20.7424671 C12.699679,19.7066541 15.3954054,18.99411 19.0198078,18.6048346 L19.0198078,23.8937554 C19.0393857,24.4009662 19.2615561,24.7305634 19.6863189,24.8825469 C20.1110818,25.0345303 20.5571214,24.9133989 21.0244379,24.5191527 L30.2086015,16 C30.7008047,15.4943945 30.9469062,14.9815893 30.9469062,14.4615846 C30.9469062,13.9415799 30.7008047,13.4343428 30.2086015,12.9398733 L21.0244379,4.68800437 C20.423584,4.25431509 19.9261908,4.15564866 19.5322583,4.39200509 C19.1383259,4.62836152 18.967509,5.28028492 19.0198078,6.34777529 L19.0198078,9.96796346 C14.8509785,10.0828638 11.3289529,11.414881 8.45373092,13.964015 C5.57850895,16.513149 3.09393197,20.6146631 1,26.2685573 Z"></path>
			</g>
		</svg>
	);
};
